/* Tabs Container */
/* Tabs Header */
.tabs-header {
  margin-bottom: 10px;
}
.tabs-header .add-note-btn {
  margin-top: 0;
}
.tabs-header-left {
  align-items: center;
}
.tabs-header-left .tab-item {
  padding: 13px 20px;
}

/* Individual Tab Items */
.tab-item {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  color: #000;
  border-bottom: 4px solid transparent;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.tab-item.active {
  font-weight: 750;
  border-bottom: 4px solid #307ef3;
}

.tab-item img {
  width: 24px;
  height: 24px;
}

/* Add Note Button */

/* .add-note-btn button {
    background-color: #4285f4;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease;
} */

/* .add-note-btn button:hover {
    background-color: #357ae8;
} */

/* App Container */
.app-container {
  padding: 20px;
  background-color: #f8f9fa;
}

/* Main Content Structure */

.main-content {
  padding: 20px;
}

.main-header h2 {
  font-size: 18px;
  font-weight: bold;
}

/* Controls (Share, Edit, etc.) */

.share-btn {
  background-color: #4285f4;
  color: white;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.share-btn:hover {
  background-color: #357ae8;
}

/* Main Body */
.main-body {
  border-top: 1px solid #0000001a;
  padding: 17px 31px;
}
.main-body textarea {
  width: 100%;
  border: 0;
  font-size: 14px;
  color: #040404;
  font-weight: 400;
  outline: 0;
  min-height: 100px;
  resize: vertical;
}

.main-body ul {
  list-style-type: none;
  padding: 0;
}

.main-body li {
  margin-bottom: 10px;
  font-size: 16px;
  color: #666;
}

/* Search and Filter */
.top-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

/* .search-input {
  flex-grow: 1;
  margin: 0 10px;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

.tabs_dropdown,
.filter-btn {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
}

.filter-btn {
  background-color: #4285f4;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.filter-btn:hover {
  background-color: #357ae8;
}

.content-container {
  display: flex;
  min-height: 400px;
}
.sidebar {
  width: 233px;
  max-height: 100%; 
  overflow-y: auto; 
  border: 1px solid #0000001a;
  border-top: 0;
  border-radius: 0 0 0 8px;
}
.sidebar-item {
  padding: 29px 17px;
  display: flex;
  justify-content: space-between;
}
.sidebar-item.active {
  background-color: #edf4ff;
}

.main-area {
  width: calc(100% - 233px);
  border-right: 1px solid #0000001a;
  border-bottom: 1px solid #0000001a;
  border-radius: 0 0 8px 0;
}
/* Responsive Design */
@media (max-width: 768px) {
  .content-container {
    flex-direction: column;
  }

  .sidebar,
  .main-area {
    width: 100%;
  }

  .tabs-header {
    flex-wrap: wrap;
    gap: 20px;
  }

  .tab-item {
    margin-bottom: 10px;
  }

  .top-controls {
    flex-direction: column;
    gap: 10px;
  }
}

/* Sidebar Styling */
.sidebar-item-title {
  font-weight: 600;
  display: flex;
  gap: 10px;
  margin: 0;
  font-size: 16px;
  color: #1e1e1e;
}
.sidebar-item-title img {
  width: 15px;
  height: 15px;
  margin-top: 4px;
}

.sidebar-item-subtitle {
  font-weight: 400;
  margin: 0;
  font-size: 16px;
  color: #1e1e1e;
}

.sidebar-item-time {
  font-size: 12px;
  color: #1e1e1e;
  font-weight: 400;
  margin: 0;
}

.tabs_dropdown {
  font-size: 14px;
}

.share-btn {
  background-color: #4285f4;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}
/* Responsive Design */
@media (max-width: 768px) {
  .main-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .editable-title {
    width: 100%;
    margin-bottom: 10px;
  }

  .tabs_dropdown,
  .share-btn {
    width: 100%;
  }
}

.tab-content {
  display: flex;
  flex-direction: column;
}

.status_report_main {
  height: calc(100vh - 350px);
  overflow-y: scroll;
}
.top-controls {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.main-header {
  padding: 29px 36px;
}

.editable-title {
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  border: 0;
  outline: 0;
  color: #1e1e1e;
}
.editable-title::placeholder {
  color: #1e1e1e;
}

.contract_main_button {
  margin-right: 50px;
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.contract_main_button .dash_common_btn {
  margin-left: 10px;
}

.controls {
  justify-content: end;
  border: 1px solid #0e1e2e1a;
  padding: 14px 31px;
  border-radius: 8px 8px 0 0;
}

.controls-status {
  justify-content: end;
  border: 1px solid #0e1e2e1a;
  /* padding: 23px 31px; */
  border-radius: 8px 8px 0 0;
}

.selected-share-option {
  font-size: 16px;
  font-weight: 600;
  color: #1e1e1e;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;
}

.tabs_dropdown {
  border: 0;
  padding: 0;
  background: transparent;
  margin: 0;
  width: 100%;
}

/* Task css */

.tasks-container {
  padding: 20px;
}

.task-item {
  margin-bottom: 10px;
  display: flex;
  gap: 10px;
}

.task-item input,
.task-item select {
  padding: 5px;
}

.task-item button {
  padding: 5px 10px;
}

.styled-select {
  font-size: 16px;
  width: 100%;
}

.tabs_dropdown-option {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.tabs_dropdown-option .name {
  font-weight: bold;
  font-size: 16px;
}

.tabs_dropdown-option .email {
  font-size: 12px;
  color: gray;
}

.bordered-date-picker {
  border: 0;
  font-size: 14px;
  padding: 0px; /* Add padding to give space inside the border */
}

.bordered-date-picker:hover {
  border-color: #007bff; /* Change border color on hover */
}

.tabs_container {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.tabs_sidebar {
  width: 25%;
  min-width: 200px;
  border-right: 1px solid #ddd;
  padding: 20px;
  background-color: #f9f9f9;
  overflow-y: auto;
}

.tabs_reportItem {
  /* margin-bottom: 15px; */
  padding: 37px 17px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
}
.tabs_reportItem p {
  font-size: 12px;
  font-weight: 400;
  color: #1e1e1e;
  margin: 0;
}
.tabs_reportTitle {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #1e1e1e;
  display: flex;
  align-items: start;
  gap: 10px;
  text-transform: capitalize;
}
.tabs_reportTitle span{
  color: #307EF3;
  font-weight: 400;
}

.tabs_reportDetails {
  width: 75%;
  padding: 20px;
  overflow-y: auto;
}

.tabs_reportDetailsContent {
  /* border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  background-color: #fff; */
}
.status_report_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
}

.tabs_editableTitle {
  font-size: 18px;
  border: 0;
  font-weight: 700;
  color: #1e1e1e;
  outline: none;
  background: none;
}
.tabs_editableTitle::placeholder {
  font-size: 18px;
  font-weight: 700;
  color: #978f8f;
}

.tabs_form {
  display: flex;
  flex-direction: column;
}

.tabs_formGroup {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 700px;
}
.tabs_formGroup label {
  width: 200px;
  font-size: 16px;
  font-weight: 500;
  /* color: #1e1e1e; */
}

.tabs_input {
  width: calc(100% - 40px - 200px);
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  color: gray;
}
.tabs_styled-select {
  width: calc(100% - 40px - 200px);
}
.css-ktrgvw-control {
  width: 100% !important;
}

.tabs_textArea {
  width: calc(100% - 40px - 200px);
  height: 100px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
}
.note_tab_content {
  height: calc(100vh - 360px);
  overflow-y: scroll;
}
.tabs_buttonGroup {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.tabs_button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tabs_buttonSecondary {
  padding: 10px 20px;
  background-color: #6c757d;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tabs_collapsibleSection {
  margin-bottom: 15px;
}

.tabs_collapsibleHeader {
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.tabs_collapsibleHeader label{
  width: 200px;
  font-size: 16px;
  font-weight: 500;
}

.task_table tr td,
.task_table tr th {
  text-align: start;
  padding-left: 20px;
}
.task_table tr td {
  width: 100% !important;
}
/* .task_table tr th {
  color: #0e1e2e66;
} */

.two_input_td {
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}
.two_input_td > div {
  width: 49%;
}
.two_input_td > div input {
  width: 100%;
  border: 0;
  outline: 0;
  background-color: transparent;
}

.save_btn_td {
  display: flex;
  align-items: center;
  gap: 10px;
}
.save_btn_td button {
  padding: 5px 10px;
}
.add_task_td p {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #0e1e2e;
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  cursor: pointer;
  width: fit-content;
}

.add_task_td p span {
  font-weight: 500;
  color: #307ef3;
}
.status_main_area {
  padding: 20px 35px;
}

.task_table_div {
  height: calc(100vh - 350px);
  overflow-y: scroll;
}




/* Ensure the modal has a proper size and allows scroll */
.dealroom_intital_modal_notes .modal-dialog {
  max-width: 600px; /* Adjust width as necessary */
  width: 100%;
}

.dealroom_intital_modal_notes .modal-body {
  max-height: 400px; /* Adjust height as necessary */
  overflow-y: auto; /* Allow scroll if content exceeds height */
}

.create_new_ul.create_new_ul2_notes {
  padding: 0;
  margin: 0;
  list-style: none;
}

.create_new_ul.create_new_ul2_notes li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.create_new_ul.create_new_ul2_notes li img {
  margin-right: 10px;
}

.create_new_ul.create_new_ul2_notes li:hover {
  background-color: #f0f0f0; /* Highlight on hover */
}

.custom-file-upload {
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  color: white; /* Text color */
  border: 1px solid #307ef3;
  background-color: #307ef3; /* Change to your desired blue color */
  border-radius: 4px; /* Optional: for rounded corners */
  text-align: center; /* Center the text */
  margin-top: 10px; /* Space above the button */
}



.sharing-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.share-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.share-option:hover {
  background-color: #f0f0f0;
}

.option-icon {
  font-size: 20px;
  margin-right: 10px;
}

.check-mark {
  font-size: 16px;
  color: green;
}

.selected {
  background-color: #e6f7ff;
}

.cancel-btn, .share-btn {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.cancel-btn {
  background-color: transparent;
  color: #999;
}

.share-btn {
  background-color: #6f42c1;
  color: white;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
   /* Light background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it is on top of other content */
}


.task_table_div {
  position: relative;
  max-height: 500px; /* Adjust height as per your needs */
  overflow-y: auto;
}

.task_table thead {
  position: sticky;
  top: 0;
  /* background-color: #fff; */
  z-index: 1;
}


.dashboard_upper_input {
  width: 300px;
  height: 50px;
  position: relative;
}

.search-input {
  width: 100%;
  height: 100%;
  padding: 10px 40px 10px 10px;
}

.sidebar-item-content{
  margin-right: 6px;
  margin-top: 2px;
}