.toggle {
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.toggle-text {
  margin-right: 10px; /* Adjust the spacing as needed */
  display: flex;
  flex-direction: column;
}

.toggle-label {
  font-size: 16px; /* Adjust the font size as needed */
  color: #000; /* Adjust the color as needed */
}

.toggle-subLabel {
  font-size: 12px; /* Adjust the font size as needed */
  color: #555; /* Adjust the color as needed */
  margin-top: 2px; /* Add some spacing between the label and sublabel */
}

.toggle input[type="checkbox"] {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.toggle label {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.toggle label:before {
  content: "";
  width: 40px;
  height: 20px;
  background: #ccc;
  position: relative;
  display: inline-block;
  border-radius: 20px;
  box-sizing: border-box;
  transition: 0.2s ease-in;
}

.toggle label:after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  left: 2px;
  top: 2px;
  z-index: 2;
  background: #fff;
  box-sizing: border-box;
  transition: 0.2s ease-in;
}

.toggle input[type="checkbox"]:checked + label:before {
  background: #307ef3;
}

.toggle input[type="checkbox"]:checked + label:after {
  left: 22px;
}
