.task-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.task-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.task-item:last-child {
  border-bottom: none;
}

.task-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  height: 45px;
}

.task-title {
  /* font-weight: bold; */
  font-size: 16px;
  margin: 0;
  margin-bottom: 4px;
  max-width: 400px;
  white-space: normal;
  overflow-wrap: break-word; 
  word-break: break-word; 
}

.task-details {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #555;
}

.task-date,
.creator-name,
.project-name {
  margin: 0;
}

.creator-name {
  font-size: 12px;
  color: #888;
}

.no_task_div {
  padding: 20px;
  text-align: center;
  font-size: 16px;
  color: #888;
}




.task-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.task-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.task-item:last-child {
  border-bottom: none;
}

.task-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

/* .task-title {
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  margin-bottom: 4px;
} */

.task-details {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #555;
}

.task-date,
.creator-name,
.project-name {
  margin: 0;
}
.task-date{
  font-size: 11px;
}
.creator-name {
  font-size: 11px;
  color: #888;
}

.no_task_div {
  padding: 20px;
  text-align: center;
  font-size: 16px;
  color: #888;
}
