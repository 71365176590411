.tooltip-container {
  position: relative;
  display: inline-block; /* Ensures the tooltip is inline with text */
}

.tooltip-container .tooltip-text {
  visibility: hidden;
  width: max-content;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  bottom: 125%; /* Adjust to place the tooltip above the text */
  left: 50%;
  margin-left: -42px; /* Adjust to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 10;
  font-size: 14px;
  white-space: nowrap;
}

.tooltip-container .tooltip-text-delete {
  visibility: hidden;
  width: max-content;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  bottom: 125%; /* Adjust to place the tooltip above the text */
  left: 50%;
  margin-left: -53px; /* Adjust to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 10;
  font-size: 14px;
  white-space: nowrap;
}

.tooltip-container .tooltip-text-review {
  visibility: hidden;
  width: max-content;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  bottom: 125%; /* Adjust to place the tooltip above the text */
  left: 50%;
  margin-left: -62px; /* Adjust to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 10;
  font-size: 14px;
  white-space: nowrap;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-container:hover .tooltip-text-delete {
  visibility: visible;
  opacity: 1;
}

.tooltip-container:hover .tooltip-text-review {
  visibility: visible;
  opacity: 1;
}
