/************** COMMON TYPOGRAPGY *****************/
._heading_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 700;
  font-size: 32px;
}
ul::-webkit-scrollbar,
div::-webkit-scrollbar {
  width: 5px;
}
ul::-webkit-scrollbar-thumb,
div::-webkit-scrollbar-thumb {
  background: #545454;
}
ul::-webkit-scrollbar-track,
div::-webkit-scrollbar-track {
  background: #c5ddf8;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.font_12 {
  font-size: 12px;
  line-height: 20px;
  margin: 0;
}
.font_14 {
  font-size: 14px;
  line-height: 26px;
  margin: 0;
}
.font_16 {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
}
.font_18 {
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

._heading_desc_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 400;
  color: #858585;
  font-size: 20px;
}

._sub_heading_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 700 !important;
  font-size: 18px;
}

._sub_heading_desc_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #858585;
}

._auth_heading_desc_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #0e1e2e;
}

._otp_heading_desc_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #858585;
}

._auth_heading_link_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 700;
  font-size: 14px;
  /* color: #5FA3DD; */
  color: #5fa3dd;
}

._auth_heading_link_ a {
  color: #5fa3dd !important;
}

._tc_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 5px;
}

._tc_link_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #5fa3dd;
}

._label_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #0e1e2e;
  margin-bottom: 10px;
}

._heading_section_ {
  margin-bottom: 32px;
}

._field_gap_ {
  margin-top: 26px;
}

._imp_ {
  color: #de6262;
}

._error_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 12px;
  color: #de6262;
  font-weight: 500;
}
form ._error_ a {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  font-weight: 500;
}

._otp-error_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 13px;
  color: #de6262;
  font-weight: 500;
}

._pointer_ {
  cursor: pointer;
}

._optional_ {
  color: #c3c3c3;
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 400;
  font-size: 14px;
}

._underline-hide_ {
  text-decoration: none;
}

._disable_ {
  cursor: none !important;
  pointer-events: none !important;
}

/****************************************************/

/************** COMMON LAYOUTS *****************/
.dflex {
  display: flex;
}
._flex_row_ {
  display: flex;
  justify-content: center;
  align-items: center;
}
._flex_end_ {
  display: flex;
  justify-content: end;
  align-items: center;
}

._flex_row_spacebtw_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

._flex_column_ {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
._flex_gap_30 {
  display: flex;
  align-items: center;
  gap: 30px;
}

._bold_font_style_ {
  font-weight: 16;
}

._light_font_style_ {
  color: #858585;
  font-size: 16px;
}

._gap_ {
  gap: 20px;
}

._gap30_ {
  gap: 30px;
}

._gap-80_ {
  gap: 78px;
}

._mt30_ {
  margin-top: 30px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

._label_mb_ {
  margin-bottom: 20px;
}

/***********************************************/

/************* COMMON MARGIN *******************/

._cm_20_ {
  margin-top: 20px;
}

/***********************************************/

._line_ {
  height: 1px;
  background: #0000001a;
  width: 136px;
}

.bottom-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* text-align: center; */
}

.card-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px;
}

._container_ {
  /* max-width: 875px; */
  max-width: 538px;
  min-width: 507px;
  padding: 50px 50px;
  /* border-top: 6px solid blue; */
  border-radius: 16px;
  background-color: #ffffff;
  position: relative;
}

._onboarding_container_ {
  max-width: 875px;
  min-width: 507px;
  padding: 50px 50px;
  /* border-top: 6px solid blue; */
  border-radius: 16px;
  background-color: #ffffff;
  position: relative;
}

._container_::before {
  content: "";
  position: absolute;
  width: 100%;
  top: -6px;
  left: 0;
  background: linear-gradient(
    90deg,
    #326aff 0%,
    #e50d0d 16.64%,
    #e5820d 32.3%,
    #e5cf0d 49.43%,
    #35d10e 69.01%,
    #0e51d1 84.67%,
    #9f0ed1 95.93%
  );
  height: 20px;
  border-radius: 16px 16px 0 0;
  z-index: -1;
}

._onboarding_container_::before {
  content: "";
  position: absolute;
  width: 100%;
  top: -6px;
  left: 0;
  background: linear-gradient(
    90deg,
    #326aff 0%,
    #e50d0d 16.64%,
    #e5820d 32.3%,
    #e5cf0d 49.43%,
    #35d10e 69.01%,
    #0e51d1 84.67%,
    #9f0ed1 95.93%
  );
  height: 20px;
  border-radius: 16px 16px 0 0;
  z-index: -1;
}

.fs-one {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 80px;
}


.fs-one img {
  width: 68px;
  height: auto;
}

.common-button {
  padding: 18px;
  background-color: #326aff;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.tranparent-button {
  padding: 18px;
  background-color: white;
  color: #afafaf;
  border: 1px solid #afafaf;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.common-button:hover,
.tranparent-button:hover {
  transform: translateY(-5px);
  box-shadow: 0px 6px 14px 0px #d7d7d740;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.btn-inner-content h4 {
  margin: 0;
  font-family: var(--xora-font-family), sans-serif;
}

.btn-inner-content img {
  width: 21px;
  height: auto;
}

.fm-logo {
  margin-bottom: 30px;
}

.fm-two {
  margin-top: 20px;
  /* margin-bottom: 50px; */
}

.main-screen {
  background-image: url(./assets/img/bg.png);
  /* background-repeat: no-repeat; */
  background-position: center;
}

.auth-wrapper {
  font-family: var(--xora-font-family);
}

/************* INPUT FIELD STYLE *******************/

._text_area_ {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  font-family: var(--xora-font-family), sans-serif;
  font-size: 12px;
  height: 70px; /* Adjust the height as needed */
  padding: 25px; /* Adjust the padding as needed */
  width: 100%;
}

._text_area_:focus {
  outline: none !important;
}

._text_area_::placeholder {
  font-family: var(--xora-font-family), sans-serif;
  color: #bbbbbb;
  /* text-align: center; */
}

._input_ {
  width: 100%;
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  outline: none !important;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  min-width: 176px;
  transition: border-color 0.3s ease;
}

._input_full_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  outline: none !important;
  width: 100%;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

._input_::placeholder,
._input_full_::placeholder {
  color: #bbbbbb;
}

._input_:focus,
._input_full_:focus {
  border-color: #326aff;
}

._input_error_ {
  width: 100%;
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  outline: none !important;
  padding: 15px;
  border: 1px solid #ff5c39;
  border-radius: 8px;
  min-width: 176px;
  transition: border-color 0.3s ease;
}

._input_full_error_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  outline: none !important;
  width: 100%;
  padding: 15px;
  border: 1px solid #ff5c39;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

._checkbox_ {
  padding: 20px;
  width: 21px;
  height: 21px;
}

._PhoneInput_ {
  border: 1px solid #e7e8ea;
  padding: 15px;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.PhoneInputInput::placeholder {
  color: #bbbbbb;
}

.phone-focused {
  border: 1px solid #326aff;
  border-radius: 5px;
}

.phone-not-focused {
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.phone-input-error {
  border: 1px solid #ff5c39;
  border-radius: 5px;
}

.PhoneInputInput {
  border: none;
  font-family: var(--xora-font-family);
  font-size: 14px !important;
  padding-left: 7px;
}

.PhoneInput.PhoneInput--focus,
input:focus {
  outline: none;
}

.PhoneInputCountry {
  border-right: 1px solid #dee2e6;
  padding-right: 10px;
}

._flex_error_ {
  position: absolute;
  left: 0;
  bottom: -19px;
  inline-size: 252px;
}


.pass-icon {
  position: absolute;
  right: 24px;
  top: 19px;
}
/***********************************************/

._card_margin_ {
  margin-top: 100px;
  margin-bottom: 100px;
}

._otp-input_wrapper_ {
  margin-top: 36px;
}

._otp-input_wrapper_ > div {
  justify-content: space-between !important;
}

._otp-input_wrapper_ input {
  font-size: 29px !important;
  width: 3em !important;
  height: 3em !important;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #0e1e2e !important;
}

._otp-input_wrapper_ input::placeholder {
  font-size: 40px !important;
  font-weight: 300;
  color: #bbbbbb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

._otp_resend_ {
  margin-top: 15px;
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  text-align: end;
}

._otp_link_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #5fa3dd;
}

._common_link_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #5fa3dd;
}

._header_ {
  height: 88px;
  padding: 10px 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
}

.container-fluid {
  padding: 0px !important;
}

._onboarding_process_wrapper_ {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
}

/************* ONBOARDING PROGRESS BAR STYLE *******************/
._dot_ {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

._progress_number_ {
  font-family: var(--xora-font-family);
  font-size: 14px;
}

._progress_name_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 16px;
  font-weight: 500;
}

._progress_line_ {
  width: 20px;
  height: 5px;
  border-radius: 3px;
}

._progress_bar_wrapper_ {
  gap: 14px;
}
/***************************************************************/

/************* ONBOARDING PROGRESS FORM STYLE *******************/
._op_card_wrapper {
  background-image: url(./assets/img/frame.png);
  background-repeat: no-repeat;
  border-radius: 8px;
  min-width: 876px;
  max-width: 876px;
  /* min-height: 662px; */
  padding: 0px 30px 30px 30px;
  margin: 50px 0 20px;
}

._op_heading_section_ {
  text-align: center;
}

._op_heading_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 700;
  font-size: 32px;
  margin-top: 40px;
}

._op_heading_desc_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 400;
  font-size: 20px;
  color: #858585;
}

._seperator_ {
  width: 100%;
  background-color: #f2f2f2;
  height: 1px;
  margin-top: 35px;
}

._op_bottom_wrapper_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

._btn_transparent_ {
  background-color: white;
  border: 1px solid #bbbbbb;
  font-family: var(--xora-font-family), sans-serif;
  border-radius: 8px;
  padding: 10px 15px 10px 20px;
  color: #bbbbbb;
  font-size: 20px;
  font-weight: 400;
}

._left_btn_icon_ {
  margin-right: 15px;
}

._btn_normal_ {
  background-color: #326aff;
  border: 1px solid #bbbbbb;
  font-family: var(--xora-font-family), sans-serif;
  border-radius: 8px;
  padding: 10px 20px 10px 15px;
  color: white;
  font-size: 20px;
  font-weight: 400;
}

._right_btn_icon_ {
  margin-left: 15px;
}

._op_form_wrapper_ {
  margin: 55px 0px;
}

._op_input_field_group_ {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

.members-list {
  display: flex;
  align-items: center;
  gap: 50px;
  margin-left: 40px;
}

._op_input_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  outline: none !important;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  min-width: 343px;
  width: 100%;
  transition: border-color 0.3s ease;
}

._op_input_::placeholder {
  color: #bbbbbb;
}

.__op_input_error_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  outline: none !important;
  padding: 15px;
  border-radius: 8px;
  min-width: 343px;
  width: 100%;
  transition: border-color 0.3s ease;
  border: 1px solid #ff5c39;
}

._dropdown_wrapper_ {
  position: relative;
}
._dropdown_wrapper_ ._dropdown_ {
  min-width: 100%;
}
._dropdown_wrapper_ ._dropdown_ input {
  width: 100%;
}

._drop-down-paceholder_ {
  color: #bbbbbb;
}

._dropdown_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  background: white !important;
  outline: none !important;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  min-width: 343px;
  width: 100%;
  min-height: 52px;
  transition: border-color 0.3s ease;
}

._dropdown-mini_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  background: white !important;
  outline: none !important;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  min-width: 273px;
  width: 100%;
  min-height: 52px;
  transition: border-color 0.3s ease;
}

._dropdown_.focused {
  border-color: #326aff;
}

._op_input_:focus,
._dropdown_:focus {
  border-color: #326aff;
}
.options {
  position: absolute;
  width: 100%;
  top: 54px;
  border-radius: 8px;
  background-color: white;
  padding: 20px;
  font-family: var(--xora-font-family), sans-serif;
  font-size: 13px;
  max-height: 150px;
  overflow-y: auto;
  scrollbar-width: thin; /* "auto" hides the scrollbar in some browsers */
  scrollbar-color: #999 transparent;
  z-index: 2;
}

.options::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
}

.options::-webkit-scrollbar-thumb {
  background-color: #999; /* Set the color of the scrollbar thumb */
  border-radius: 4px; /* Round the corners of the thumb */
}

.options::-webkit-scrollbar-thumb:hover {
  background-color: #666; /* Change thumb color on hover */
}

.options::-webkit-scrollbar-track {
  background-color: transparent; /* Set the color of the scrollbar track */
}

.option {
  padding: 8px;
  cursor: pointer;
}

.option:hover {
  background-color: #f0f0f0;
  border-radius: 8px;
}

._dropdown_icon_ {
  position: absolute;
  top: 19px;
  right: 19px;
  cursor: pointer;
  transition: all 200ms ease-in-out;
}
._dropdown_wrapper_ .options ~ img {
  transform: rotate(180deg);
  transition: all 200ms ease-in-out;
}
button:focus-visible {
  outline: 0;
}
.__op_input_error_::placeholder {
  color: #bbbbbb;
}
.upload-logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.uploaded-logo {
  font-family: var(--xora-font-family), sans-serif;
  border: 1px solid #e7e8ea;
  padding: 13px;
  min-width: 343px;
  border-radius: 8px;
  font-size: 12px;
  min-height: 52px;
}

.uploaded-logo > a {
  text-decoration: none !important;
}

._three-dot-text_ {
  display: inline-block;
  width: 180px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
  margin-left: 5px;
}

.upload-btn > button {
  background-color: #326aff;
  border: 1px solid #e7e8ea;
  font-family: var(--xora-font-family), sans-serif;
  color: white;
  padding: 13px;
  min-width: 120px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
}

.upload-btn {
  background-color: #326aff;
  border: 1px solid #e7e8ea;
  font-family: var(--xora-font-family), sans-serif;
  color: white;
  padding: 13px;
  min-width: 120px;
  border-radius: 8px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.upload-btn-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

._invite_ > h3 {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 20px;
  font-weight: 400;
  margin-left: 45px;
  margin-top: 25px;
}

._role_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
}

._role_input_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  outline: none !important;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  width: 100%;
  transition: border-color 0.3s ease;
}

._goal_grid_wrapper_ {
  margin: 55px 10px;
}

._shadow_ {
  box-shadow: 0 0 8px #326aff;
}

._goal_card_ {
  border: 1px solid #bbbbbb;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
}

._selected_goal_card_ {
  border: 1px solid #326aff;
  border-radius: 8px;
  text-align: center;
  padding: 10px;
}

._goal_card_:hover {
  border: 1px solid #326aff;
}

._goal_card_desc_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0px !important;
  margin-top: 8px;
}

._grid_gap_ {
  margin-top: 30px;
}
/***************************************************************/

._forget-pass_ {
  text-align: end;
}

.pp-wrapper {
  margin: 55px 25px;
}

.pp-desc {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 12px;
  color: #bbbbbb;
}

.rs-ref {
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  padding: 15px;
}

.rs-ref-select {
  border: 1px solid #326aff;
  border-radius: 8px;
  padding: 15px;
}

.rs-ref:hover {
  border-color: #326aff;
}

.rs-text {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
}

._congo_heading_ {
  font-family: var(--xora-font-family), sans-serif;
  font-weight: 700;
  font-size: 32px;
  margin-top: 20px;
}

._congo_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #326aff;
  margin-top: 53px;
}

._congo_label_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.success-gif {
  margin-top: 40px;
}

.success-gif > img {
  width: 200px;
  height: 200px;
}

.added-role-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

._congo_screen_ {
  display: flex;
  align-items: center;
  gap: 40px;
}

._child1_ {
  width: 24%;
}
._child2_ {
  width: 70%;
  border: 1px solid #e7e8ea;
  padding: 20px;
  border-radius: 8px;
}

._xora-email_ {
  width: 70%;
  border: 1px solid #e7e8ea;
  padding: 33px;
  border-radius: 8px;
}

._xora-email_ > h4 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  margin: 0px;
}

._logo_section_ {
  display: flex;
  gap: 20px;
  align-items: center;
}
.enter_email_btn ._field_gap_ {
  width: 46%;
}
._goal_grid_wrapper_ ._shadow_ {
  background-color: #b16d961f;
  box-shadow: 0 0 3px #326aff;
}
._field_gap_ .rs-ref-select {
  background-color: #b16d961f;
  box-shadow: 0 0 3px #326aff;
}
.congo_para {
  max-width: 761px;
  margin: auto;
}
.error_main_div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.error_main_div button {
  margin-top: 40px;
}
.error_img {
  max-height: 500px;
  max-width: 500px;
}

/* Dashboard */
.dashboard_main {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  height: 100vh;
}
.dashboard_left {
  width: 270px;
  border-right: 1px solid #cecece;
  padding: 24px;
  height: 100vh;
}
.sidebar_main_collapsed {
  width: 100px !important;
  overflow: hidden;
  padding: 24px 15px;
}
.sidebar_main_collapsed ~ .dashboard_right {
  width: calc(100% - 100px);
}
.sidebar_main_collapsed .dash_logo div img {
  display: none;
}
.menubar_sidebar {
  transition: transform 0.3s;
}
.menubar_sidebar_rotated {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
.menubar_sidebar:hover {
  cursor: pointer;
}

.sidebar_scroll.collapse {
  display: block;
}
.sidebar_scroll_collapse .dash_left_li {
  padding: 15px 20px;
}
.sidebar_scroll {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.sidebar_scroll::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.dash_left_arrow {
  margin: 0 20px;
  visibility: hidden;
}
.dash_left_arrow_active {
  visibility: visible;
  cursor: pointer;
}
.dashboard_left .dash_logo {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 20px 0 60px;
  padding: 0 20px;
}
.dash_left_ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dash_left_li {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
}
.dash_left_li:hover {
  cursor: pointer;
}
.dash_left_li.active {
  background-color: #307ef3;
  border-radius: 16px;
  padding: 20px;
  color: #fff;
  position: relative;
}
.dash_left_li.active::before {
  position: absolute;
  content: "";
  background-color: #fff;
  height: 38px;
  width: 4px;
  left: 0px;
  border-radius: 10px;
  bottom: 11px;
}
.dash_left_li.active img {
  filter: brightness(5);
}
.dash_left_li.active p {
  color: #fff;
}
.dash_left_li img {
  height: 24px;
  width: 24px;
}
.dash_left_li p {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
  color: #656b73;
  font-weight: 400;
}
.dash_left_heading {
  margin: 16px 20px;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
}
.sidebar_main_collapsed .dash_left_heading_underline {
  border-bottom: 1px solid #d9d9d9;
  margin: 5px 0;
}
.dashboard_right {
  width: calc(100% - 270px);
}
.dashboard_upper {
  padding: 20px 80px 20px 28px;
  border-bottom: 1px solid #cecece;
  justify-content: end;
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
  background: #fff;
}

/* .gray__bg {
  background: #f9f9f9;
  min-height: 100vh;
} */

.dashboard_upper2 {
  justify-content: space-between;
}
.dashboard_upper_bussiness_name {
  font-size: 24px;
  margin: 0;
  text-transform: capitalize;
  font-weight: 600;
  color: #307ef3;
}
.dashboard_upper_bussiness_logo {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dashboard_upper_bussiness_logo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.dashboard_upper_right {
  display: flex;
  align-items: center;
  gap: 20px;
}
.dashboard_upper_input {
  position: relative;
}
.dashboard_upper_input input {
  border: 1px solid #cecece;
  padding: 8px 8px 8px 40px;
  border-radius: 6px;
  color: #000;
  font-size: 14px;
  line-height: 21px;
  position: relative;
}
.dashboard_upper_input img {
  position: absolute;
  left: 15px;
  top: 11px;
}

.dashboard_lower {
  margin: 10px 28px;
  padding: 20px;
  overflow: auto;
  height: calc(100vh - 98px);
}
.heading_symbol {
  display: flex;
  align-items: center;
  gap: 15px;
}
.dashboard_heading {
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  margin: 0;
}
.dashboard_sub {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard_sub_right {
  display: flex;
  align-items: center;
  gap: 20px;
  position: relative;
}
.dash_common_btn {
  box-shadow: none !important;
  padding: 10px 15px;
  border: 1px solid #307ef3;
  background-color: #307ef3;
}

.modal_btn_div {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 98px;
}
.modal_div_button {
  padding: 8px 15px;
}
.dash_common_btn:hover {
  transition: none;
  transform: none;
}
.dash_common_btn ._gap_ {
  flex-direction: row-reverse;
  gap: 10px !important;
}
.dash_common_reverse ._flex_row_ {
  flex-direction: row;
}
.dash_common_reverse ._flex_row_ img {
  width: 14px;
  height: 14px;
}
.white_bg_btn {
  background-color: white;
  border-color: #307ef3;
  color: #307ef3;
}
.a9a9a9_color_btn {
  background-color: white;
  border-color: #a9a9a9;
  color: #a9a9a9;
}
.d3d3d3_bg_btn {
  background-color: #d3d3d3;
  border: 0px solid #d3d3d3;
  color: white;
}
.ff4500_bg_btn {
  background-color: #ff4500;
  border: 0px solid #ff4500;
  color: white;
}
.arrow_scroll_ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  overflow-y: hidden;
  margin: 30px 0;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.arrow_scroll_ul::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.arrow_scroll_li:first-child {
  text-align: center;
  padding: 10px 25px;
  min-width: 200px;
  position: relative;
  /* background: linear-gradient(to right, #0093e9, #80d0c7); */
  margin-right: 20px;
  /* box-shadow: none !important; */
  border: 1px solid #34343428;
  background-color: #f7f9ff;
}
.arrow_scroll_li:first-child h5,
.arrow_scroll_li:first-child p {
  /* color: white; */
  /* opacity: 100%; */
}

.arrow_scroll_li:not(:first-child) {
  background-color: #f7f9ff;
  text-align: center;
  padding: 10px 25px;
  min-width: 200px;
  border: 1px solid #34343428;
  position: relative;
}

.arrow_scroll_li:not(:first-child)::after,
.arrow_scroll_li:not(:first-child)::before {
  position: absolute;
  content: "";
  height: 70%;
  width: 22px;
  background-color: white;
  border: 1px solid #34343428;
  right: -9px;
  top: -11px;
  border-bottom: 0;
  transform: rotate(-24deg);
  z-index: 9;
}

.arrow_scroll_li:not(:first-child)::before {
  bottom: -9px;
  top: auto;
  transform: rotate(24deg);
}
.arrow_scroll_li:last-child::before,
.arrow_scroll_li:last-child::after {
  border-right: 0;
}

.arrow_scroll_li h5 {
  line-height: 40px;
  font-weight: 500;
  font-size: 24px;
  color: #000000;
  opacity: 75%;
  margin: 0;
}
.arrow_scroll_li p {
  margin: 0;
  color: #0000007a;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  position: relative;
}
.arrow_scroll_li:not(:first-child) p::after {
  position: absolute;
  content: "";
  background-color: white;
  right: -32px;
  bottom: 24px;
  width: 10px;
  height: 15px;
  z-index: 10;
}
.arrow_scroll_li.active_scroll_li {
  background: linear-gradient(to right, #0093e9, #80d0c7);
}
.active_scroll_li h5,
.active_scroll_li p {
  color: #fff;
  opacity: 100%;
}

.dashboard_detail {
  display: flex;
  justify-content: space-between;
}
.dashboard_detail_left {
  width: 69%;
  height: calc(100vh - 330px) !important;
  overflow-y: scroll;
  min-height: 300px;
}
.dashboard_detail_left1 {
  height: calc(100vh - 200px) !important;
}
.dashboard_detail_left_main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.dashboard_detail_left ._dropdown_ {
  min-width: 100%;
}
.dashboard_detail_right {
  width: 30%;
  overflow-y: scroll;
    max-height: calc(100vh - 330px);
    min-height: 300px;
}
.dash_task,
.dash_invoice {
  border: 1px solid #d7dbe7;
  width: 49%;
  padding: 15px;
  border-radius: 8px;
  position: relative;
}
.no_task_div {
  position: absolute;
  width: 100%;
  height: 75%;
  left: 0;
  bottom: 0;
  background-color: #f7f9ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}
.no_task_div p {
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
  margin: 0;
}
.dash_task_upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dash_task_view {
  margin: 0;
  color: #307ef3;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
}
.lets_invite_div {
  gap: 50px;
}
.full_task_div {
  height: 200px;
  position: relative;
}
.full_task_div .no_task_div {
  height: 90%;
}
.dash_task_ul {
  height: 300px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.dash_task_li {
  border-bottom: 1px solid #edf1fa;
  padding: 20px 0 10px;
  display: flex;
  align-items: start;
  gap: 10px;
}
.dash_task_li_div h4 {
  color: #14213d;
  font-weight: 500;
}
.dash_task_li_div h5 {
  color: #14213d;
  font-weight: 400;
}
.dash_task_li_div p {
  color: #71778e;
  font-weight: 400;
}
.dash_task_input_div {
  padding-top: 4px;
}
.dash_invoice_ul {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-top: 20px;
}
.dash_invoice_li {
  width: calc(100% / 2 - 8px);
  background-color: #f7f9ff;
  border-radius: 8px;
  padding: 10px;
}
.dash_invoice_li h5 {
  color: #71778e;
}
.dash_invoice_li p {
  margin-top: 8px;
}
.dash_task_main {
  border: 1px solid #d7dbe7;
  width: 100%;
  padding: 15px;
  border-radius: 8px;
  position: relative;
}
.dash_task_main_li {
  padding: 10px 0 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #edf1fa;
}
.dash_task_main_li h4 {
  padding: 0 15px 0 10px;
  color: #14213d;
  font-weight: 400;
}
.dash_task_main_li p {
  padding-left: 15px;
  border-left: 1px solid #e9eaec;
  color: #7c8db5;
  font-weight: 400;
}
.dash_recent,
.dash_action,
.dash_fav {
  border: 1px solid #d7dbe7;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  position: relative;
}
.dash_action_ul {
  border: 1px solid #edf1fa;
  padding: 15px 20px;
  border-radius: 8px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 15px;
}
.dash_action_li {
  width: calc(100% / 2 - 10px);
}
.dash_fav {
  margin: 15px 0;
}
.dash_fav_ul {
  margin-top: 10px;
  height: 140px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}
.dash_fav_li {
  padding: 15px 0;
  border-bottom: 1px solid #edf1fa;
  display: flex;
  gap: 15px;
}
.dash_fav_li_div h5 {
  color: #71778e;
  line-height: 16px;
}
.dash_notes {
  width: 100%;
}
.dash_notes .dash_task_ul {
  height: 500px;
  margin-top: 10px;
}
.dash_notes .dash_task_li_div h5 {
  color: #71778e;
  line-height: 16px;
}

.modal_heading {
  font-size: 28px;
  line-height: 42px;
  font-weight: 700;
  color: #0e1e2e;
  margin: 0;
}
.modal_main_ul {
  margin-top: 22px;
}
.modal_main_li {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}
.modal_main_li ._dropdown_wrapper_ ._dropdown_ {
  padding: 0;
  min-width: 100%;
}
.modal_main_li .css-13cymwt-control {
  font-size: 14px;
  outline: none !important;
  width: 100%;
  padding: 7px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  color: #9b9a9a;
  height: 53px;
}
.modal_main_li label {
  color: #0e1e2e;
  margin-bottom: 5px;
}
.modal_main_li label ._imp_ {
  color: #de6262;
}
.modal_main_li label span {
  color: #c3c3c3;
}
.modal_main_li input,
.modal_main_li textarea {
  font-size: 14px;
  outline: none !important;
  width: 100%;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  color: black;
}
.modal_main_li input::placeholder {
  color: #bbbbbb;
}
.modal_main_li ._input_full_error_ {
  border-color: #de6262;
}
.modal_two_field {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.modal_two_field_left {
  width: 49%;
}
.select_color_div {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #e7e8ea;
  margin-top: 15px;
  box-shadow: 0px 10px 15px 0px rgba(107, 107, 107, 0.1);
}
.client_tag_div {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.client_tag_div span {
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: 500;
  border-radius: 6px;
}
.client_tag_div img:hover {
  cursor: pointer;
}
.color-selector {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}
.modal_selector_div {
  width: calc(100% / 7 - 10px);
}
.modal_selector_div button {
  width: 24px;
  height: 24px;
  border-radius: 6px;
  border: 0;
  border: 3px solid transparent;
}
.modal_selector_div .active {
  border: 3px solid #716d6d;
}
.modal_main_li .select_color_label {
  margin-bottom: 16px;
}
.create_modal_btn {
  width: 100%;
  padding: 16px;
  background-color: #326aff;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-top: 15px;
  border: 0;
  border-radius: 8px;
}
.modal_color_btn_div {
  margin-top: 80px;
}
.logut_btn {
  padding: 12px 16px;
}
.project_main_upper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 0 27px;
}
.project_main_upper .dashboard_upper_input input {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  padding: 13px 13px 13px 40px;
}
.project_main_upper .dashboard_upper_input img {
  top: 16px;
}
.project_main_upper_btn {
  display: flex;
  gap: 20px;
  align-items: center;
  position: relative;
}
.project_table {
  width: 100%;
  border: 1px solid #0e1e2e10;
  border-radius: 8px;
}
.project_table thead {
  background-color: #f5f7fb;
}
.project_table tbody tr:nth-child(even) {
  background-color: #f7faff;
}
.project_table tr th,
.project_table tr td {
  padding: 16px 5px;
  font-size: 14px;
  line-height: 20px;
}
.project_table tr th {
  color: #000000;
  opacity: 70%;
  font-weight: 600;
}
.project_table tr td {
  color: #0e1e2e;
  font-weight: 400;
}
.project_table .status {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
}

.project_table .status span {
  height: 8px;
  width: 8px;
  border-radius: 50%;
}


.project_table .phases {
  padding: 6px;
  font-size: 12px;
  line-height: 16px;
  color: #ffffff;
  font-weight: 400;
  margin: 0;
  border-radius: 4px;
  max-width: 120px;
}
.project_table .grey_phases {
  background-color: grey;
}
.project_table .execution_status {
  background-color: #f7980a;
}
.project_table .intial_status {
  background-color: #6092ff;
}
.project_filter_main {
  position: absolute;
  width: 276px;
  background-color: white;
  top: 50px;
  z-index: 9;
  right: 150px;
  box-shadow: 0 -2px 43px 0 rgba(0, 0, 0, 0.06);
}
.project_filter_main ._btn_transparent_ {
  border-color: #307ef3;
  color: #307ef3;
}
.project_customize {
  right: 0;
}
.project_customize .project_filter_accor {
  overflow: hidden;
}
.project_filter_upper {
  padding: 18px 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;
  justify-content: space-between;
  margin-bottom: 10px;
}
.project_filter_upper h4 {
  margin: 0;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}
.project_filter_upper img:hover {
  cursor: pointer;
}
.project_filter_accor .accordion-button,
.project_filter_accor .accordion-body {
  font-size: 14px;
  padding: 14px 20px;
}
.project_filter_accor .accordion-button {
  border-bottom: 1px solid #ececec;
}
.project_filter_accor .accordion-item {
  border: 0;
}
.project_filter_accor .accordion-button:not(.collapsed),
.project_filter_accor .accordion-button:focus {
  box-shadow: none;
}
.project_filter_accor {
  height: 320px;
  overflow-y: scroll;
}
.project_filter_accor .accordion-button:not(.collapsed) {
  background-color: white;
}
.project_filter_btn {
  margin-top: 0px;
  padding: 24px 15px 20px;
  justify-content: space-between;
  border: 1px solid #ececec;
}
.project_filter_btn button {
  font-size: 14px;
  line-height: 20px;
  padding: 16px 10px;
  width: 118px;
  font-weight: 500;
}
.projectdetails_dash_upper {
  justify-content: space-between;
  flex-wrap: wrap;
}
.projectdetails_dash_left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.projectdetails_dash_left h3 {
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  color: #1e1e1e;
  margin: 0;
}
.projectdetails_navtab_ul {
  display: flex;
  justify-content: space-evenly;
  gap: 50px;
  align-items: center;
  margin-top: 35px;
}
.projectdetails_navtab_ul .projectdetails_navtab_li:not(:last-child)::after {
  content: "";
  position: absolute;
  background-color: #e9e9e9;
  width: 1px;
  height: 20px;
  top: 3px;
  right: -26px;
}
.projectdetails_navtab_li {
  position: relative;
  min-width: 90px;
  text-align: center;
  cursor: pointer;
}
.projectdetails_navtab_li:hover {
  cursor: pointer;
}
.projectdetails_navtab_li p {
  font-weight: 500;
  padding-bottom: 6px;
}
.projectdetails_navtab_li.active p {
  font-weight: 600;
  border-bottom: 5px solid #307ef3;
}
.projectdetails_dash_lower {
  margin: 10px 28px;
  margin-bottom: 0;
  padding: 20px;
  overflow: auto;
  height: calc(100vh - 98px);
}

.projectdetails_dash_lower .quill {
  max-width: 80%;
  margin: 0 auto;
  height: calc(100vh - 325px) !important;
}

.contract_preview_bottom > div {
  height: calc(100vh - 388px) !important;
  width: 80% !important;
}

.projectdetails_clientdiv {
  width: 100%;
  border: 1px solid #d7dbe7;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.projectdetails_clientdiv .modal_main_ul {
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.projectdetails_clientdiv .modal_main_ul li {
  width: 49%;
}
.projectdetails_notes_heading {
  color: #bbbbbb;
}
.projectdetails_notes_input {
  font-size: 14px;
  background: white !important;
  outline: none !important;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  width: 100%;
  min-height: 52px;
  margin: 10px 0 5px;
}
.dash_recent {
  margin-top: 29px;
}
.dash_recent_ul {
  margin-top: 14px;
}
.dash_recent_li_figure_div {
  width: 55px;
  border-radius: 50%;
  background-color: #fbee9f;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-size: 22px;
  font-weight: 500;
}
.dash_recent_li {
  display: flex;
  flex-wrap: wrap;
  /* gap: 2px; */
}
.dash_recent_li_figure {
  width: 60px;
}
.dash_recent_li_figcap {
  width: calc(100% - 85px);
}
.dash_recent_li_figcap h3 {
  color: #363f5e;
  font-weight: 500;
}
.dash_recent_li_figcap h3 .font_weight {
  font-weight: 600;
}
.dash_recent_li_figcap h3 .font_color {
  font-weight: 600;
  color: #69d0ca;
}
.dash_recent_li_figcap h3 .font_date {
  color: #7c8db5;
}

.dash_recent_li_para {
  padding: 16px 20px;
}

._editable_ {
  display: flex;
  align-items: center;
  gap: 20px;
}
._editable_ .hehjr{
  width: 100%;
  padding-right: 20px;
}
._editable_ .hehjr input{
  max-width: 100%;
}
.customize_pop_ul {
  padding: 0 20px;
}
.customize_pop_li {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ececec;
  padding: 10px 0;
}
.customize_pipeline_sub {
  display: flex;
  gap: 5px;
  align-items: center;
}
.customize_pipeline_add {
  width: 100%;
  justify-content: space-between;
}
.industry_dropdown_cont {
  gap: 90px;
}
.customize_pipeline_add input {
  width: 80%;
  border: 1px solid #ececec;
  background-color: transparent;
  padding: 5px;
  border-radius: 10px;
  font-size: 14px;
}
.customize_pipeline_add button {
  width: 15%;
  border: none;
  background-color: transparent;
  color: grey;
}
.customize_pop_li p {
  color: #0e1e2e;
  font-weight: 500;
}
.fav_star {
  height: 35px;
  width: 35px;
}
.fav_star:hover {
  cursor: pointer;
}

.loading {
  opacity: 0.5; /* Light faded */
  pointer-events: none; /* Non-interactive */
}
.loader_table {
  width: 100%;
  text-align: center;
}
.loader_table .text-light {
  color: #000000 !important;
}
._dropdown_ .selected-option,
.css-1dimb5e-singleValue {
  color: #000 !important;
  font-size: 14px !important;
}
.dash_notes input[type="checkbox"],
.filter_dropdown_li input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none; /* For WebKit browsers */
  -moz-appearance: none; /* For Firefox */
  width: 18px;
  height: 18px;
  border: 1px solid #bdc1d0; /* Customize the border color and thickness */
  border-radius: 4px; /* Optional: add rounded corners */
  position: relative;
  cursor: pointer;
}
.dash_notes input[type="checkbox"]:checked::before,
.filter_dropdown_li input[type="checkbox"]:checked::before {
  content: "";
  position: absolute;
  top: 2px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
#addTagForm1,
#addTagForm {
  margin-top: 10px;
}
.filter_dropdown_li {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 7px;
}
.filter_dropdown_li label {
  font-size: 13px;
  color: #9b9a9a;
  font-weight: 500;
}
.filter_dropdown_li input[type="checkbox"] {
  width: 15px;
  height: 15px;
}
.filter_dropdown_li input[type="checkbox"]:checked::before {
  left: 4px;
  height: 8px;
}
.customize_pipeline {
  right: 180px;
  z-index: 99;
}
.customize_pipeline .project_filter_accor {
  height: auto;
}
.modal-backdrop.show {
  opacity: 76%;
}
.name_intials_dash_upper {
  font-weight: 500;
  height: 40px;
  width: 40px;
  font-size: 16px;
  background-color: #307ef3;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logout_main_div:hover .logout_div {
  visibility: visible;
  transition: all 100ms ease-in-out;
}
.logout_div {
  position: absolute;
  right: 72px;
  bottom: -22px;
  background: #307ef3;
  width: 90px;
  z-index: 9999;
  text-align: end;
  padding: 8px 9px;
  border-radius: 10px;
  border: 0;
  color: #fff;
  visibility: hidden;
  transition: all 100ms ease-in-out;
  text-align: center;
}
.logout_div p {
  margin: 0;
  font-weight: 600;
}
.logout_div p:hover {
  cursor: pointer;
}
.modal_main_li .css-1dimb5e-singleValue {
  text-transform: capitalize;
}

._dropdown_ .important-color {
  color: var(--dynamic-color) !important;
}

.main_details_div > div {
  display: none;
}
.main_details_div > div.dashboard_detail_active {
  display: inline-flex;
  width: 100%;
}
.dashboard_detail_active .dashboard_detail_left {
  height: 760px;
}
.main_details_div > div.active {
  display: block;
}

.ql-container {
  display: flex;
  justify-content: center;
}

.ql-editor {
  text-align: center;
  margin: 0 auto;
}

.date-picker-popper {
  z-index: 10000 !important; /* Ensure it is on top of other elements */
}

.date-picker-wrapper {
  width: 100%;
}

/* Modal Popup */
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 510px;
    width: 100%;
  }
}
.modal-content {
  padding: 58px 48px;
  position: relative;
  border-radius: 15px;
}


.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.spinner-grow {
  margin: auto;
}

.loader_table {
  width: 100%;
  text-align: center;
}
.loader_table .text-light {
  color: #000000 !important;
}

.modal-content::before {
  content: "";
  position: absolute;
  width: 100%;
  top: -6px;
  left: 0;
  background: linear-gradient(
    90deg,
    #326aff 0%,
    #e50d0d 16.64%,
    #e5820d 32.3%,
    #e5cf0d 49.43%,
    #35d10e 69.01%,
    #0e51d1 84.67%,
    #9f0ed1 95.93%
  );
  height: 20px;
  border-radius: 16px 16px 0 0;
  z-index: -1;
}

._dropdown_input_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  outline: none !important;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  /* min-width: 343px;
  width: 50%; */
  max-width: 235px;
  transition: border-color 0.3s ease;
}

._dropdown_input_::placeholder {
  color: #bbbbbb;
}

._dropdown_input_:focus {
  border-color: #326aff;
}

.highlighted-option {
  background-color: #f0f0f0;
  border-radius: 8px;
}

/************************************************/

._dropdown-container {
  position: relative;
  width: 300px;
}

._input-container {
  display: flex;
  align-items: center;
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  outline: none !important;
  padding: 12px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  min-width: 343px;
  width: 100%;
  transition: border-color 0.3s ease;
}

._input-field {
  width: 100%;
  border: none;
  outline: none;
}

._arrow-icon {
  cursor: pointer;
  margin-left: 5px;
}

._dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background: white;
  z-index: 1;
  border-radius: 4px;
}

._dropdown-item {
  padding: 10px;
  cursor: pointer;
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
}

._dropdown-item:hover,
._dropdown-item.highlight {
  /* background-color: #f0f0f0; */
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
}

/****************************************************/
.__dropdown {
  min-width: 400px !important;
  position: absolute;
  top: 50px;
  right: 0px;
  background: whitesmoke;
  border: 1px solid #ccc;
  z-index: 999;
  max-height: 428px;
  overflow-y: auto;
  border-radius: 8px;
}

.__dropdown ul {
  list-style-type: none;
  padding: 20px 0px;
  margin: 0;
}

.__dropdown li {
  padding: 10px 45px;
  cursor: pointer;
}

.__dropdown li:hover {
  background-color: #f0f0f0;
}

.no_task_div_ {
  position: absolute;
  width: 100%;
  height: 75%;
  left: 0;
  bottom: 0;
  background-color: #f7f9ff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.no_task_div_ p {
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
}

/* Dealroom UI */

.dealroom_detail_upper {
  margin: 30px 0 40px;
  position: relative;
  z-index: 999;
}

.dealroom_detail_upper_left {
  position: relative;
}
.dealroom_detail_upper_left .project_filter_main {
  right: 0;
}
.dealroom_detail_upper_left .dashboard_upper_input input {
  background-color: #f9f9f9;
  border: 0;
  padding: 13px 13px 13px 37px;
}
.dealroom_detail_upper_left .dashboard_upper_input img {
  top: 17px;
}
.dealroom_detail_upper_right {
  position: relative;
}

.dealroom_table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 40px;
}
.dealroom_table th,
.dealroom_table td {
  width: 16.6%;
}
.dealroom_table .dealroom_table_title {
  text-decoration: underline;
}
.dealroom_table .phases.signed_phases {
  background-color: #278829;
}
.dealroom_table .phases.sent_phases {
  background-color: #165964;
}
.dealroom_table .phases.expired_phases {
  background-color: #eb0000;
}
.dealroom_table .phases.draft_phases {
  background-color: #d84008;
}
.dealroom_table .phases.viewed_phases {
  background-color: #ebebeb;
  color: #000;
}
.create_new_ul {
  position: absolute;
  background-color: white;
  top: 45px;
  left: 0px;
  padding: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  width: 140px;
  z-index: 9;
}
.create_new_ul2 {
  top: auto;
  bottom: -125px;
  left: 20px;
}
.create_new_ul li {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  padding: 10px;
  border-bottom: 0.5px solid #ececec;
}
.create_new_ul li:hover {
  cursor: pointer;
}
.isCreateNewButtonActive img {
  transform: rotate(180deg);
  transition: transform 0.3s;
}
.dealroom_detail_upper_right .dash_common_btn img {
  transition: transform 0.3s;
}
.dealroom_intital_modal .modal-content::before {
  content: none;
}
.dealroom_intital_modal .modal-content {
  padding: 0px;
}
.dealroom_intital_modal .modal-header {
  border-bottom: 0;
}
.dealroom_intital_modal .modal-body {
  text-align: center;
}
.dealroom_intital_modal .modal-body h3 {
  font-size: 25px;
  line-height: 37px;
  margin: 10px 0 2px;
  color: #0a2540;
}
.dealroom_intital_modal .modal-body p {
  color: #6c757d;
}
.dealroom_intital_modal .modal-body h6 {
  text-align: start;
  display: flex;
  gap: 10px;
  color: #4f4f4f;
  font-weight: 400;
  margin-bottom: 25px;
}

.dealroom_intital_modal_div {
  justify-content: space-between;
  display: flex;
}
.dealroom_intital_modal_div button {
  justify-content: space-between;
  display: flex;
  width: 43%;
  justify-content: center;
  margin: 35px 0 30px;
  padding: 18px;
}
.signature_div {
  margin-top: 20px;
}
.signature_div_main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}
.signature_div_middle {
  max-width: 522px;
  width: 100%;
  padding: 45px 20px 55px;
  text-align: center;
  border: 2px dashed #cbd0dc;
  border-radius: 8px;
}
.signature_div_middle h4 {
  color: #292d32;
  font-weight: 500;
  margin: 10px 0;
}
.signature_div_middle p {
  color: #a9acb4;
  margin-bottom: 19px;
}

/* contract */
.contract_main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
/* .contract_left {
  width: 79%;
} */

.contract_left {
  width: 800px;
  height: calc(100vh - 290px) !important;
  display: block;
  margin: 0 auto;
}
.contract_left .modal_main_ul {
  display: flex;
  justify-content: space-between;
}
.contract_left .modal_main_li {
  width: 49%;
}
.contract_left_template {
  border: 1px solid #0e1e2e1a;
  padding: 25px 32px;
  border-radius: 8px;
  position: relative;
}
.contract_right {
  width: 20%;
  border: 1px solid #0e1e2e1a;
  padding: 30px 25px;
  border-radius: 8px;
  height: 70vh;
  overflow-y: scroll;
}
.contract_right_image {
  display: flex;
  gap: 10px;
  align-items: center;
}
.contract_right_image img {
  width: 30px;
  height: 30px;
  background-color: #929292;
  /* background-color: #f3b8b8; */
  border-radius: 4px;
  padding: 6px;
}

.contract_right .assign_ul li:hover {
  color: black;
  padding: 14px 0;
}

.contract_right .dashboard_heading {
  margin-bottom: 20px;
}
.contract_right label {
  font-weight: 500;
}
.contract_right .assign_ul {
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;
}
.contract_right .assign_ul li {
  padding: 9px 25px;
  text-align: center;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid #0e1e2e1a;
  /* background-color: #e3cff4; */
  margin-bottom: 15px;
  color: white;
  transition: padding 0.2s ease;
}

.highlight-current-signer {
  border: 10px solid;
  border-color: black !important;
  color: black !important;
  font-weight: 800 !important;
}

.contract_right_labeling_main {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.contract_right_labeling {
  border-radius: 8px;
  font-weight: 500;
  line-height: 22px;
  width: 100%;
  padding: 7px 20px;
  border: 1px solid gray;
  cursor: pointer;
  transition: padding 0.2s ease;
}

.contract_right .nav-pills {
  width: 100%;
  padding: 2px;
  border: 1px solid #e2e2e2;
  border-radius: 6px;
}
.contract_right .nav-item {
  width: 50%;
  text-align: center;
  font-weight: 500;
}
.contract_main_button {
  position: fixed;
  bottom: 0;
  right: 20px;
  display: flex;
  background: rgb(255, 255, 255);
  padding: 20px 70px 20px 30px;
  gap: 20px;
  align-items: center;
  width: calc(100% - 290px);
  justify-content: end;
  z-index: 999;
}
.sidebar_main_collapsed ~ .dashboard_right .contract_main_button {
  width: calc(100% - 120px);
}
.contract_preview_upper {
  border: 1px solid #0000001a;
  padding: 15px 30px 30px;
  margin: 40px 0 20px;
  border-radius: 8px;
}
.contract_preview_upper img {
  margin-bottom: 20px;
  min-width: 30px;
  height: 38px;
  display: block;
  margin: auto;
}
.contract_preview_upper_ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.contract_preview_upper_ul li h4 {
  color: #000000;
  font-weight: 600;
}
.contract_preview_bottom {
  border: 1px solid #0000001a;
  padding: 38px 30px;
  border-radius: 8px;
}
.templategallery_main {
  display: flex;
  height: 1000px;
}
.templategallery_left {
  width: 249px;
  padding-top: 34px;
  overflow-y: scroll;
  height: 74vh;
}
.templategallery_left h2 {
  color: #0e1e2e;
}
.templategallery_left_ul {
  margin: 18px 0 20px;
}
.templategallery_left_ul li {
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.templategallery_left_ul li input[type="checkbox"] {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #d7dbe7;
  border-radius: 3px;
  position: relative;
}

.templategallery_left_ul li input[type="checkbox"]:checked::before {
  content: "✔";
  position: absolute;
  top: -5px;
  left: 0px;
  color: blue;
}

.templategallery_left_ul li label {
  color: #0e1e2e;
  font-weight: 400;
}
.templategallery_right {
  width: calc(100% - 249px);
  overflow: auto;
  height: calc(100vh - 150px);
  padding: 40px 35px;
}
.templategallery_right .dealroom_detail_upper {
  margin-top: 0;
}
.templategallery_right_main {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
}
.templategallery_right_sub {
  width: calc(100% / 5 - 20px);
  padding: 10px;
}
.templategallery_right_sub:hover {
  cursor: pointer;
}
.templategallery_right_sub h3 {
  margin-top: 7px;
  text-align: center;
  font-weight: 600;
  color: #0a2540;
}
.templategallery_image_main_ {
  border: 1px solid #ececec;
  text-align: center;
  margin: 0 20px;
  padding: 10px;
}

.preview_btn {
  background-color: red;
}

.select_signer_ul {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.select_signer_ul li {
  width: calc(100% / 3 - 50px);
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #d6d6d6;
  padding: 13px;
  border-radius: 8px;
}
.select_signer_ul li p {
  margin: 0;
  color: #404040;
  font-weight: 600;
}
.add_signer_ul {
  margin-top: 10px;
}

.add_signer_ul li {
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0;
}
.add_signer_ul li input {
  width: 100%;
}
.add_signer_ul li img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.applied-filters .status {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 3px;
  opacity: 0.5;
}

.applied-filters .phase {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 3px;
  opacity: 0.5;
}
.add_signer_ul li input {
  width: 100%;
}
.add_signer_ul li img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.dealroom_signer_lower {
  margin-top: 40px;
  border-top: 1px solid #eeeeee;
  padding-top: 40px;
}
.dealroom_signer_main {
  height: 70vh;
  overflow-y: scroll;
}

.app-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.react-pdf__Page {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  margin-bottom: 5px;
}

.preview-template .react-pdf__Page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.higlight-signer-box {
  border-color: #000 !important;
}

.select_signer_li {
  border: 1px solid rgb(235, 229, 229);
  padding: 10px;
  border-radius: 8px;
}

/* SignatureModal.css */
.signature-modal {
  /* footer{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000;
}

.signature-modal .modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  width: 336px;
}

.sigCanvas {
  border: 4px solid #e0dede;
  border-radius: 4px;
}

.document-view {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececec;
  /* border: 1px solid black; */
  overflow: auto;
}

.signature-modal-button {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.signs-options-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.signs-options .signs-options_inner {
  background: lightgrey;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.signs-options h4 {
  font-size: 15px;
  font-weight: 400;
}

.signs-options {
  border: 1px solid transparent;
  border-radius: 6px;
}

.signs-options:hover {
  border: 1px solid grey;
}

.color-box {
  width: 16px;
  height: 16px;
  /* background-color: #0093e9; */
}

.signer-color-details {
  display: flex;
  align-items: center;
  gap: 10px;
  font: var(--xora-font-family);
  font-size: 12px;
  margin-bottom: 8px;
}

.dynamic-input-fields-wrapper > input {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  outline: none !important;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.dynamic-input-fields-wrapper > input::placeholder {
  color: #bbbbbb;
}

:focus {
  border-color: #326aff;
}

/* ._input_full_ {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  outline: none !important;
  width: 100%;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  transition: border-color 0.3s ease;
} */

.dynamic-input-fields-wrapper > input:focus {
  border-color: #326aff;
}

.pdf-section-wrapper {
  display: flex;
  flex-direction: column;
}

.logo-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  gap: 50px;
  font-family: var(--xora-font-family);
}

.logo-section-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff; /* or any other background color */
  z-index: 99999;
  padding: 4px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.user-email-section {
  display: flex;
  justify-content: center;
  align-items: baseline;
  align-items: end;
  gap: 10px;
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30%;
}

.edit-pdf-button {
  padding: 10px;
  border-radius: 8px;
  font-family: var(--xora-font-family);
  color: white;
  background-color: #0093e9;
}

.table-scrolling {
  height: 500px;
  overflow-y: scroll;
}
.preview .react-pdf__Page__canvas {
  width: 900px !important;
  height: 900px !important;
}

/* .preview-pdf .react-pdf__Page__canvas {
  width: 900px !important;
  height: 900px !important;
} */

.action-icons {
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
}

.action-icons img {
  cursor: pointer;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.action-cross-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.action-modal-wrapper {
  position: relative;
  font-family: var(--xora-font-family);
  font-size: 16px;
}

.action-approval-btn-wrappers {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.datePickerWrapper {
  width: 85%;
}

.thumb-Images {
  list-style-type: none;
  padding: 0;
}

.thumb-Images li {
  width: 115px;
  float: left;
  display: inline-block;
  vertical-align: top;
  height: 120px;
  margin-right: 10px;
}

.thumb {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.img-wrap {
  position: relative;
  display: inline-block;
  font-size: 0;
}

.img-wrap .close {
  position: absolute;
  top: 2px;
  z-index: 1;
  background-color: #d0e5f5;
  padding: 3px 2px;
  color: #000;
  font-weight: bolder;
  cursor: pointer;
  opacity: 0.5;
  font-size: 23px;
  line-height: 10px;
  border-radius: 50%;
}

.nav-link {
  color: #000;
}

.nav-link:hover {
  color: #000;
}

.nav-link.active {
  color: #0d6efd !important;
}

.client-signature-status {
  font-family: var(--xora-font-family);
}

.clear-button {
  background-color: #f0f0f0;
  border: none;
  color: #333;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-left: auto;
}

.clear-button:hover {
  background-color: #e0e0e0;
}

.clear-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.invoice_main {
  margin-top: 22px;
}
.invoice_main .invoice_main_sub {
  width: 25%;
}
.invoice_main_sub p {
  font-size: 14px;
  color: #0e1e2e;
  font-weight: 400;
}
.invoice_main_sub .dateselect {
  width: 70px;
}
.invoice_main_sub ._dropdown_ {
  min-width: auto;
}
/* .invoice_main_sub ._dropdown_ input {
footer p{
  text-decoration: underline;
} */

.select_signer_ul {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.select_signer_ul li p {
  margin: 0;
  color: #404040;
  font-weight: 600;
}
.add_signer_ul {
  margin-top: 10px;
}

.add_signer_ul li {
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0;
}
.add_signer_ul li input {
  width: 100%;
}
.add_signer_ul li img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.applied-filters .status {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 3px;
  opacity: 0.5;
}

.applied-filters .phase {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 3px;
  opacity: 0.5;
}
.add_signer_ul li input {
  width: 100%;
}
.add_signer_ul li img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.dealroom_signer_lower {
  margin-top: 40px;
  border-top: 1px solid #eeeeee;
  padding-top: 40px;
}
.dealroom_signer_main {
  height: 70vh;
  overflow-y: scroll;
}

.app-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.react-pdf__Page {
  margin-bottom: 5px;
}

.preview-template .react-pdf__Page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.higlight-signer-box {
  border-color: #000 !important;
}

.select_signer_li {
  border: 1px solid rgb(235, 229, 229);
  padding: 10px;
  border-radius: 8px;
}

.signature-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000000000;
}

.signature-modal .modal-content {
  background: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  width: 336px;
}

.sigCanvas {
  border: 4px solid #e0dede;
  border-radius: 4px;
}

.document-view {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ececec;

  overflow: auto;
}

.signature-modal-button {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.signs-options-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.signs-options .signs-options_inner {
  background: lightgrey;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
}

.signs-options h4 {
  font-size: 15px;
  font-weight: 400;
}

.signs-options {
  border: 1px solid transparent;
  border-radius: 6px;
}

.signs-options:hover {
  border: 1px solid grey;
}

.color-box {
  width: 16px;
  height: 16px;
}

.signer-color-details {
  display: flex;
  align-items: center;
  gap: 10px;
  font: var(--xora-font-family);
  font-size: 12px;
  margin-bottom: 8px;
}

.dynamic-input-fields-wrapper > input {
  font-family: var(--xora-font-family), sans-serif;
  font-size: 14px;
  outline: none !important;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.dynamic-input-fields-wrapper > input::placeholder {
  color: #bbbbbb;
}

:focus {
  border-color: #326aff;
}

.dynamic-input-fields-wrapper > input:focus {
  border-color: #326aff;
}

.pdf-section-wrapper {
  display: flex;
  flex-direction: column;
}

.logo-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  gap: 50px;
  font-family: var(--xora-font-family);
}

.logo-section-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 99999;
  padding: 4px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.user-email-section {
  display: flex;
  justify-content: center;
  align-items: baseline;
  align-items: end;
  gap: 10px;
}

.section-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30%;
}

.edit-pdf-button {
  padding: 10px;
  border-radius: 8px;
  font-family: var(--xora-font-family);
  color: white;
  background-color: #0093e9;
}

.table-scrolling {
  height: 500px;
  overflow-y: scroll;
}
.preview .react-pdf__Page__canvas {
  width: 900px !important;
  height: 900px !important;
}
.action-icons {
  align-items: center;
}

.action-icons img {
  cursor: pointer;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
}

.action-cross-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.action-modal-wrapper {
  position: relative;
  font-family: var(--xora-font-family);
  font-size: 16px;
}

.action-approval-btn-wrappers {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: center;
}

.datePickerWrapper {
  width: 85%;
}

.thumb-Images {
  list-style-type: none;
  padding: 0;
}

.thumb-Images li {
  width: 115px;
  float: left;
  display: inline-block;
  vertical-align: top;
  height: 120px;
  margin-right: 10px;
}

.thumb {
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  overflow: hidden;
}

.img-wrap {
  position: relative;
  display: inline-block;
  font-size: 0;
}

.img-wrap .close {
  position: absolute;
  top: 2px;
  z-index: 1;
  background-color: #d0e5f5;
  padding: 3px 2px;
  color: #000;
  font-weight: bolder;
  cursor: pointer;
  opacity: 0.5;
  font-size: 23px;
  line-height: 10px;
  border-radius: 50%;
}

.nav-link {
  color: #000;
}

.nav-link:hover {
  color: #000;
}

.nav-link.active {
  color: #0d6efd;
}

.client-signature-status {
  font-family: var(--xora-font-family);
}

.clear-button {
  background-color: #f0f0f0;
  border: none;
  color: #333;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-left: auto;
}

.clear-button:hover {
  background-color: #e0e0e0;
}

.clear-button:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.invoice_main {
  margin-top: 22px;
}
.invoice_main_div {
  height: calc(100vh - 285px);
}
.react-datepicker-popper {
  z-index: 9999;
}
.invoice_main .invoice_main_sub {
  width: 25%;
}
.invoice_main_sub p {
  font-size: 14px;
  color: #0e1e2e;
  font-weight: 400;
}
.invoice_main_sub .dateselect {
  width: 70px;
}
.invoice_main_sub .dropdown {
  min-width: auto;
}
.invoice_main_sub .dropdown input {
  width: 100%;
}
.invoice_main_sub .monthselect {
  width: 200px;
  margin-left: 20px;
}
.invoice_main_list {
  margin-top: 20px;
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
}
.invoice_main_list .modal_main_ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.invoice_main_list .modal_main_ul li {
  width: 49%;
}
.invoice_main_lower {
  justify-content: space-between;
}
.invoice_main_lower_left {
  max-width: 345px;
  width: 100%;
}
.invoice_main_lower_left p {
  color: #0e1e2e;
  font-weight: 400;
  line-height: 22px;
}
.invoice_main_lower_left_main {
  border: 1px solid #0e1e2e1a;
  padding: 16px 24px 16px 16px;
  height: 100%;
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
}
.invoice_main_lower_right {
  max-width: 687px;
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 8px;
  height: fit-content;
}
.invoice_total_main_left {
  width: 80%;
}
.invoice_total_main_right {
  width: 21%;
}
.invoice_main_select ._dropdown_wrapper_,
.invoice_main_select ._dropdown_,
.invoice_main_select ._dropdown_ input,
.invoice_total_main_right2 {
  flex-direction: row;
  gap: 10px;
}
.invoice_main_select .dropdown_wrapper,
.invoice_main_select .dropdown,
.invoice_main_select .dropdown input {
  width: 100%;
  min-width: 100%;
}
.invoice_total_main_left_heading {
  font-size: 14px;
  line-height: 16px;
  color: #1e1e1e;
  font-weight: 500;
  max-width: 120px;
  width: 100%;
  margin: 0;
}
.invoice_total_main_left1 {
  gap: 20px;
}
.invoice_total_dropdown1 {
  width: 100%;
}
.invoice_total_dropdown2 {
  max-width: 120px;
  width: 100%;
}
.invoice_total_dropdown3 {
  max-width: 150px;
  width: 100%;
}
.nav_tab_scheuling .nav-pills {
  width: 100%;
}
.nav_tab_scheuling .nav-item {
  width: 49%;
  text-align: center;
}
.nav_tab_scheuling .nav-item a {
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #307ef3;
  padding: 12px;
}
.nav_tab_scheuling .nav-item a.active {
 color: #fff !important;
}
.scheduling_div_heading {
  font-size: 25px;
  font-weight: 700;
  color: #1e1e1e;
}
.scheduling_div_toogle {
  margin-bottom: 0;
  margin-top: 30px;
}
.scheduling_div_toogle2 {
  margin-top: 20px;
  margin-bottom: 37px;
}
.scheduling_div_toogle .toggle {
  width: 100% !important;
}
.scheduling_div_toogle .toggle .toggle-label {
  font-weight: 500;
}
.nav_tab_scheuling {
  margin-top: 50px;
}
.scheduling_table tr th {
  padding-left: 20px;
  text-align: start;
}
.scheduling_table tr td {
  padding-left: 20px;
  text-align: start;
}
.scheduling_table tr td input {
  background-color: transparent;
  width: 100%;
}
.scheduling_table tr .another_mile {
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
  cursor: pointer;
}
.additonal_note {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #0e1e2e;
  margin: 30px 0 8px;
}

@media screen and (max-width: 1480px) {
  .invoice_main_select .dropdown_wrapper,
  .invoice_main_select .dropdown,
  .invoice_main_select .dropdown input {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 1440px) {
  ._header_ {
    height: 75px;
  }

  ._container_ {
    padding: 30px;
    min-width: 460px;
    max-width: 460px;
  }
  ._onboarding_container_ {
    padding: 30px;
  }
  ._otp-input_wrapper_ input {
    font-size: 25px !important;
  }
  ._heading_ {
    font-size: 28px;
  }
  ._input_full_,
  ._input_full_error_,
  ._input_,
  ._PhoneInput_,
  ._input_error_,
  ._op_input_,
  .__op_input_error_,
  ._dropdown_,
  .uploaded-logo {
    padding: 12px 15px;
  }
  .projectdetails_navtab_li {
    min-width: 80px;
    text-align: center;
  }
  .uploaded-logo {
    min-height: 48px;
  }
  .upload-btn {
    padding: 11px;
  }
  ._congo_ {
    margin-top: 30px;
  }
  ._tc_ .ms-2 {
    font-size: 13px;
  }
  ._field_gap_ img {
    max-height: 50px;
    max-width: 50px;
  }
  .common-button,
  .tranparent-button {
    padding: 13px;
  }

  ._heading_section_ {
    margin-bottom: 20px;
  }
  ._op_heading_ {
    font-size: 29px;
    margin-top: 33px;
  }


  ._seperator_ {
    margin-top: 25px;
  }

  ._op_form_wrapper_ {
    margin: 40px 0px;
  }

 

  ._dropdown_ {
    min-height: 48px;
  }


  .pp-wrapper {
    margin: 25px 20px;
  }

  ._invite_ > h3 {
    font-size: 17px;
  }

  ._op_form_wrapper_ {
    margin: 24px 0px;
  }

  ._goal_grid_wrapper_ {
    margin: 35px 10px;
  }

  .rs-text {
    font-size: 12px;
  }

  .rs-ref {
    padding: 13px;
  }
  .dashboard_lower {
    margin: 5px 10px;
    padding: 10px;
  }
  .dashboard_upper {
    padding: 10px 40px 10px 15px;
  }
  .arrow_scroll_ul {
    margin: 15px 0;
  }
  .arrow_scroll_li {
    padding: 5px 26px;
    min-width: 180px;
  }
  .arrow_scroll_li::after,
  .arrow_scroll_li::before {
    height: 76%;
    width: 21px;
  }
  .arrow_scroll_li::before {
    bottom: -7px;
  }
  .arrow_scroll_li p::after {
    bottom: 19px;
  }
  .dashboard_left {
    width: 280px;
  }
  .dashboard_right {
    width: calc(100% - 280px);
  }
  .dashboard_heading {
    font-size: 18px;
    line-height: 25px;
  }
  .arrow_scroll_li {
    min-width: 180px;
  }
  .arrow_scroll_li h5 {
    line-height: 30px;
    font-size: 21px;
  }
  .dash_task_ul {
    height: 240px;
  }
  .dash_recent {
    margin-top: 20px;
  }
  .dash_action_ul {
    padding: 10px 15px;
    margin-top: 8px;
    gap: 10px;
  }
  .dash_action_li {
    width: calc(100% / 2 - 5px);
  }
  .dash_common_btn {
    padding: 7px 10px;
  }
  .dash_fav_li {
    padding: 10px 0;
  }
  .dash_fav_ul {
    margin-top: 8px;
    height: 120px;
  }
  .dash_invoice_ul {
    margin-top: 10px;
  }
  .sidebar_scroll {
    height: calc(100vh - 200px);
    overflow-y: scroll;
  }
  .dash_task_li {
    padding: 10px 0;
  }
  .dash_task,
  .dash_invoice {
    padding: 10px;
  }
  .font_16 {
    font-size: 15px;
    line-height: 20px;
  }
  .font_14 {
    font-size: 13px;
  }
  .dash_notes .dash_task_ul {
    height: 110px;
  }
  .dash_recent,
  .dash_action,
  .dash_fav {
    padding: 10px;
  }
  .project_filter_upper {
    padding: 10px 20px;
    margin-bottom: 7px;
  }
  .project_filter_accor .accordion-button,
  .project_filter_accor .accordion-body {
    padding: 10px 20px;
  }
  .project_filter_accor {
    height: 250px;
  }
  .project_filter_btn button {
    padding: 10px;
  }
  .project_customize .project_filter_accor {
    overflow-y: scroll;
  }
  .contract_right {
    padding: 15px 13px;
  }
  .dashboard_detail_left {
    height: 550px;
  }
  .dashboard_detail_active .dashboard_detail_left {
    height: 660px;
  }
}

@media screen and (max-width: 1380px) {
}

@media screen and (max-width: 1080px) {
  .sidebar_main {
    position: absolute;
    left: 0;
    background: white;
    z-index: 9;
  }
  .sidebar_main ~ .dashboard_right {
    width: calc(100% - 100px);
    margin-left: auto;
  }
  .projectdetails_navtab_ul {
    overflow-y: scroll;
    max-width: 900px;
    margin: 35px auto 0;
    text-wrap: nowrap;
    justify-content: start;
  }
  .projectdetails_navtab_li {
    min-width: auto;
  }
  .projectdetails_navtab_ul::-webkit-scrollbar {
    height: 2px;
  }
}
@media screen and (max-width: 980px) {
  .modal-content {
    padding: 30px;
  }
}

@media screen and (max-width: 768px) {
  .user-email-section {
    display: none;
  }
}

@media screen and (max-width: 614px) {
  .document-view {
    display: block;
  }
}

canvas.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.react-pdf__Page {
  width: 100% !important;
}

.react-pdf__Document.test {
  width: 100%;
}

.textLayer {
  inset: auto !important;
}
.react-pdf__Page {
  border: 1px solid gray;
}

.document-view {
  max-width: 80% !important;
  margin: 0 auto;
  display: block !important;
  border: 0 !important;
  padding-right: 5px;
  background: transparent;
}

.signers-color-details-section {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
}

.mm_box {
  position: absolute;
  border-radius: 4px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  opacity: 1;
}

.signature_img {
  max-width: 100%;
  width: auto;
  height: auto !important;
}

.document-view_inner {
  margin-top: 86px;
}

.ql-editor {
  width: 100%;
}

span.file-types {
  max-width: 115px !important;
}

.cm_singer_box {
  z-index: 99999;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  text-align: center;
}

.cm_singer_box p {
  margin: 0;
  font-size: 14px;
}

.cm_singd_box img,
.cm_singer_box img {
  width: 75%;
  max-width: 132px;
  max-height: 50px;
  object-fit: contain;
}

.cm_singd_box img {
  width: 75%;
}

.for_sign_underline {
  border-top: 1px solid #000;
  margin-top: 10px;
  text-align: center;
}

.cm_singd_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid gray;
  margin: 20px 0;
  padding: 20px;
}

.react-pdf__Page__textContent.textLayer,
.contract_right {
  display: none !important;
}

img.signature_img {
  max-width: 132px;
  max-height: 50px;
}

.contract_left > .contract_left_template {
  height: calc(100vh - 275px) !important;
}

.line_or {
  border-bottom: 1px solid #e9e9e9;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.line_or span {
  position: relative;
  bottom: -11px;
  background: #fff;
  padding: 0 15px;
}

.cm_placeholder {
  color: #c6c6c6;
}

.cm_search input {
  width: 320px;
  background: #f9f9f9;
  border: 0;
  padding: 7px;
  padding-left: 36px;
}

.cm_search {
  position: relative;
}

.cm_search img {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.file_content_body {
  border: 1px solid #0e1e2e1a;
  border-radius: 8px;
  padding: 40px;
  margin-top: 15px;
  height: calc(100vh - 287px);
  overflow: auto;
}

.folder_card {
  width: calc(100% / 5);
  padding: 10px;
  text-align: center;
  margin-bottom: 30px;
}

.folder_icon {
  width: 150px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ececec;
  border-radius: 8px;
  background: #fffdfd;
  margin: 0 auto;
  position: relative;
}

.folder_card b {
  display: block;
  font-size: 16px;
  font-weight: 600;
  margin-top: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
}

.cm_drop_dwon .dropdown-toggle::after {
  display: none !important;
}

.my_modal .modal-content {
  padding: 20px;
}

.my_modal .modal-body {
  padding: 20px 0;
}

.dropdown_option .dropdown,
.folder_icon .dropdown {
  position: absolute;
  right: 0;
  top: 0;
}

.cm_share_drop_opt .dropdown-toggle:focus,
.cm_share_drop_opt .dropdown-toggle,
.dropdown_option .dropdown-toggle:focus,
.dropdown_option .dropdown-toggle,
.folder_icon .dropdown-toggle:focus,
.folder_icon .dropdown-toggle {
  padding: 0;
  background: transparent;
  border: 0;
  box-shadow: none;
}

.sm_pdf_redner {
  width: 52px;
}

.cm_err {
  display: inline-block;
  color: red;
  font-size: 13px;
  margin-top: 8px;
}

.cm_breadcrumbs {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.cm_breadcrumbs li {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: #9da0a6;
}

.cm_breadcrumbs li:hover {
  color: #000000;
}

.other_btn_sign {
  display: flex;
  align-items: center;
}

.other_btn_sign img {
  height: 48px;
  margin-left: 40px;
}

.other_btn_sign .for_sign_underline {
  border-top: 0;
  border-bottom: 1px solid #000;
  margin: 0;
}

.other_btn_sign .btn.btn-dark {
  width: 150px;
}

.folder_icon > img {
  width: 49px;
  cursor: pointer;
}

.gallery_image {
  height: 225px;
  width: 225px;
  position: relative;
}

.gallery_image img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.media_select {
  position: absolute;
  width: 28px;
  height: 28px;
  left: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 9;
}

.media_select input {
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
}

.media_select span {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid #307ef3;
  background: #fff;
  border-radius: 100%;
  left: 0;
  top: 0;
  box-shadow: 2px 2px 11px rgba(0, 0, 0, 0.5);
}

.media_select input:checked + span {
  background: #307ef3;
  border-color: #307ef3;
}

.media_select input:checked + span::after {
  content: "";
  position: absolute;
  width: 13px;
  height: 8px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  transform: rotate(-50deg);
  left: 5px;
  top: 6px;
}

.selected_action {
  margin-left: -40px;
  border-bottom: 1px solid #e7e8eb;
  padding-bottom: 15px;
  margin-top: -35px;
  padding-left: 40px;
  margin-bottom: 15px;
  margin-right: -40px;
}

.image_sm_action {
  display: flex;
  padding: 10px;
}

.image_sm_action span {
  cursor: pointer;
}

.image_sm_action span {
  width: 34px;
  height: 34px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image_sm_action .active {
  background: #0d6efd;
}

.image_sm_action .active path {
  fill: #fff;
}

.cm_table_view thead tr th {
  background: #F5F7FB;
  color: #0E1E2E66;
  font-weight: 500;
  border: 0;
}

.cm_table_view {
  font-size: 14px;
}

.cm_table_view .table-striped > tbody > tr:nth-of-type(odd) > td {
  background: #F7FAFF !important;
  color: #0E1E2E;
  border: 0;

}

.cm_table_view .table-striped > tbody > tr:nth-of-type(even) > td {
  background: #fff !important;
  color: #0E1E2E;
  border: 0;

}


.cm_breadcrumbs li.active {
  color: #000;
}


.media__img {
  width: 100%;
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ECECEC;
  border-radius: 8px;
  background: #FFFDFD;
  margin: 0 auto;
  position: relative;
}

.media__img img {
  width: 70%;
  height: 70%;
}

.cm_share_drop_opt .dropdown-menu {
  display: flex;
}

.dropdown-item button {
  margin: 0 !important;
}

.cm_share_drop_opt .dropdown-toggle {
  margin-top: 0 !important;
}


.cm_share_drop_opt_box {
  border: 1px solid #dadada;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  position: absolute;
  right: -20px;
  top: 37px;
  background: #fff;
  z-index: 9;
}

.cm_share_drop_opt_box > div {
  margin: 0px;
}

span.cm_share_drop_opt {
  position: relative;
}

.cm_share_drop_opt_box button {
  margin: 0;
  margin: 0px 8px;
}

.cm_share_drop_opt_box svg {
  width: 24px;
  height: 24px;
}



.page_wrapper {
  background: #F9F9F9;
  height: calc(100vh - 81px);
  padding: 40px;
  overflow: auto;
}

.invoices_amount_chip_card {
  border: 1px solid #deecff;
  background: #f5f7ff;
  border-radius: 8px;
  padding: 12px;
  min-height: 118px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.invoices_list_wrapper,
.invoices_pie_charts {
  background: #fff;
  border: 1px solid rgba(14, 30, 46, 0.1);
  border-radius: 8px;
  padding: 40px;
}

.cm_doughnut_wrap {
  max-width: 270px;
}

.border-right {
  border-right: 1px solid rgba(231, 231, 231, 1);
}

.invoices_amount_chip_card h5 {
  font-size: 18px;
  font-weight: 600;
}

.invoices_amount_chip_card p {
  color: rgba(113, 119, 142, 1);
}

.cm_ui_select__control {
  background: transparent !important;
  border-color: rgba(14, 30, 46, 0.1) !important;
}

/* .cm_ui_select__control--is-focused {
  border-color: rgba(14, 30, 46, 0.5);
} */

.cm_ui_select__indicator-separator {
  display: none !important;
}

.filter_btn:hover,
.filter_btn:focus,
.filter_btn:active,
.filter_btn.active,
.filter_btn {
  background: transparent !important;
  color: #0d6efd;
}

.filter_btn::after {
  display: none !important;
}

.btn_outline_primary:hover,
.btn_outline_primary:focus,
.btn_outline_primary:active,
.btn_outline_primary.active,
.btn_outline_primary {
  border: 1px solid rgba(48, 126, 243, 1);
  color: rgba(48, 126, 243, 1);
  font-weight: 600;
}

.pro_drop {
  position: relative;
}

.pro_drop_box {
  position: absolute;
  width: 300px;
  background-color: white;
  top: 38px;
  z-index: 9;
  box-shadow: 0 -2px 43px 0 rgba(0, 0, 0, 0.06);
  right: 0;
}

.pro_drop_box_head {
  padding: 15px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;
  justify-content: space-between;
}

.pro_drop_box_head h4 {
  margin: 0;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
}

.pro_drop_box_footer {
  padding: 15px;
  display: flex;
  align-items: center;
  border-top: 1px solid #ececec;
  justify-content: space-between;
}

.pro_drop_box_body {
  max-height: 200px;
  overflow: auto;
  min-height: 200px;
}

.teams_heading {
  font-size: 25px;
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.cm_drop_dwon2 .dropdown-item {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

._form_lable label {
  font-size: 14px;
  margin-bottom: 6px;
}

.template_page_wrapper {
  background: #f9f9f9;
  min-height: 100vh;
  max-height: 100vh;
  padding: 20px;
  overflow: auto;
}

.template_box_wrapper {
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  min-height: calc(100vh - 115px);
}

.template_box_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.template_box_header h3 {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 0;
}

._template_box {
  margin: 20px;
  margin-bottom: 30px;
}

._template_box_inner {
  border: 1px solid rgba(236, 236, 236, 1);
  background: rgba(255, 253, 253, 1);
  width: 152px;
  text-align: center;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-bottom: 10px;
  position: relative;
}

._template_box h5 {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.dropdown_option .dropdown {
  top: 6px;
}

.template_filter h3 {
  font-size: 14px;
  color: #0e1e2e;
  margin-bottom: 20px;
  margin-top: 40px;
}

.template_filter {
  width: 260px;
  padding: 20px 10px;
  height: calc(100vh - 280px);
  overflow-y: scroll;
}

.template_filter ul li {
  font-size: 14px;
  margin-bottom: 9px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.vender_modal label {
  font-size: 14px;
  margin-bottom: 6px;
}

.vender_modal label {
  font-size: 14px;
  margin-bottom: 6px;
}

.cm_profile_upload {
  width: 100px;
  height: 100px;
  background: linear-gradient(0deg, #326aff 0%, #e50d0d 16.64%, #e5820d 32.3%, #e5cf0d 49.43%, #35d10e 69.01%, #0e51d1 84.67%, #9f0ed1 95.93%);
  border-radius: 100%;
  position: relative;
  margin: 30px auto;
}

.cm_profile_upload_inner {
  position: absolute;
  width: 94px;
  height: 94px;
  background: #fff;
  border-radius: 100%;
  left: 3px;
  top: 3px;
}

.cm_profile_upload_inner input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  font-size: 0;
  cursor: pointer;
  z-index: 99;
  opacity: 0;
}


img.pmm2 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
}

img.pmm1 {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  border-radius: 100%;
}

img.pmm0 {
  position: absolute;
  right: -1px;
  bottom: -5px;
  width: 35px;
  background: #fff;
  border-radius: 100%;
  padding: 5px;
  border: 1px solid #b9b9b9;
}

.res_err {
  color: red;
  font-size: 13px;
}










/* setting page start */


.settings_page_wrapper {
  padding-left: 300px;
  position: relative;
  height: calc(100vh - 62px);
  overflow: auto;
}

.setting_sidebar {
  position: absolute;
  width: 300px;
  border-right: 1px solid rgba(222, 222, 222, 1);
  height: calc(100vh - 62px);
  overflow: auto;
  left: 0;
  top: 0;
}

.setting_sidebar_box h6 {
  font-size: 14px;
  font-weight: 600;
  color: rgba(0, 0, 0, 1);
  margin-bottom: 20px;
  margin-top: 10px;
}

.setting_sidebar_box {
  padding: 10px;
  border-bottom: 1px solid rgba(222, 222, 222, 1);
}

._for_admins {
  color: rgba(195, 195, 195, 1);
  font-weight: 400;
}

.setting_sidebar_box p {
  margin: auto;
  font-size: 12px;
  font-weight: 400;
  color: rgba(16, 16, 16, 1);
  padding: 10px 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.setting_sidebar_box p.active {
  color: #307ef3;
}

.setting_sidebar_box p.active svg path {
  fill: #307ef3;
}

.setting_sidebar_box p span {
  margin-left: 10px;
}


.setting_inner_page {
  padding: 40px;
}


.setting_inner_page h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 30px;
}

.setting_inner_page label {
  font-size: 14px;
  margin-bottom: 6px;
}


.medium_padding .modal-content {
  padding: 0 !important;
}

.cm_my_phone_input .PhoneInputCountrySelect {
  pointer-events: none;
}


.cash_setup_status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
}

.cash_setup_status h6 {
  font-size: 21px;
  font-weight: 300;
  padding: 20px;
  border-right: 2px solid rgba(230, 230, 230, 1);
  margin-left: 10px;
  margin-right: 20px;
  margin-bottom: 0;
}

.cfs_chip {
  padding: 12px 30px;
  border-radius: 30px;
  color: #fff;
}

.cfs_1 {background: rgba(154, 165, 176, 1)}
.cfs_2 {background: rgba(233, 131, 58, 1)}
.cfs_3 {background: rgba(247, 195, 37, 1)}
.cfs_4 {background: rgba(32, 120, 46, 1)}
.cfs_5 {background: rgba(222, 12, 12, 1)}


.payment_status h5 {
  font-size: 16px;
  font-weight: 600;
  margin-top: 50px;
}

.section_heading {
  font-size: 25px;
  font-weight: 700;
}


/* setting page End */


.section_filter {
  border: 1px solid #f0f1f2;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  position: relative;
  z-index: 999;
}

.no_size .toggle {
  width: auto !important;
}

.global_library .file_content_body {
  height: calc(100vh - 175px);
}

.error-text {
  color: red;
  font-size: 14px;
}


@media(max-width: 1188px){
  .user-email-section {
    display: none;
  }


}

@media(max-width: 650px){
  .user-email-section {
    display: none;
  }

  .in_mob_section_1 {
    display: block;
  }

  .document-view_inner.document_view_inner_in_mobile {
    margin-top: 140px;
  }


}

@media(max-width: 420px){
  .in_mob_section_1 {
    gap: 10px;
    width: 100%;
  }

  .cm_singer_box {
    display: block;
  }

  .other_btn_sign img {
    height: 30px;
    margin-left: 20px;
  }

  .document-view_inner.document_view_inner_in_mobile {
    margin-top: 120px;
  }


}

._is_disable {
  opacity: 0.2;
  cursor: not-allowed;
}




.c_header {
  position: relative;
}

.c_header:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: -2px;
  left: 0;
  background: linear-gradient(90deg, #F70000 0%, #DABB18 18.5%, #E37322 36%, #49B209 55%, #00A2B8 70%, #2A70DA 85.5%, #DC1AED 100%);
  height: 20px;
  border-radius: 16px 16px 0 0;
  z-index: -1;
}

.c_header_inner {
  display: flex;
  background: #fff;
  position: relative;
  z-index: 9;
  padding: 20px;
}

.c_logout_wrap {
  display: flex;
}

.c_name_top {
  font-weight: 500;
  height: 40px;
  width: 40px;
  font-size: 16px;
  background-color: #307ef3;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.c_name_top p {
  right: 0;
  bottom: -60px;
  background: #307ef3;
  width: 90px;
  z-index: 9999;
  padding: 8px 9px;
  border-radius: 10px;
  border: 0;
  color: #fff;
  visibility: hidden;
  transition: all 100ms ease-in-out;
  text-align: center;
  position: absolute;
  cursor: pointer;
}

.c_name_top:hover p {
  visibility: visible;
  transition: all 100ms ease-in-out;
}

.min_300__control {
  min-width: 300px;
}

.c_body {
  padding: 40px;
}

.c_body h3 {
  font-size: 25px;
  font-weight: 700;
  color: #1E1E1E;
}

.c_body p {
  font-size: 14px;
}


.client_satus {
  display: flex;
  margin: 0 auto;
  align-items: center;
  font-weight: 600;
  color: #0E1E2E;
  font-size: 14px;
}

.client_satus span {
  font-size: 12px;
  color: #fff;
  padding: 4px 15px;
  border-radius: 4px;
  font-weight: 400;
}

.a_sidebar {
  width: 270px;
  border-right: 1px solid #cecece;
  padding: 24px;
  height: calc(100vh);
  position: absolute;
  left: 0;
  top: 0;
  overflow: auto;
}

.a_sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 40px;
}

.a_sidebar ul li {
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  position: relative;
}

.a_sidebar ul li p {
  margin: 0;
  margin-left: 10px;
}

.a_sidebar ul li.active {
  background-color: #307ef3;
  border-radius: 16px;
  color: #fff;
  padding: 20px;
  position: relative;
}

.a_sidebar ul li.active img {
  filter: brightness(5);
}

.a_sidebar ul li.active:before {
  background-color: #fff;
  border-radius: 10px;
  bottom: 11px;
  content: "";
  height: 38px;
  left: 0;
  position: absolute;
  width: 4px;
}

.a_body {
  width: calc(100% - 270px);
  margin-left: 270px;
}

.min_width__control {
  min-width: 250px;
}

.back_btn_inner {
  position: fixed;
  left: 0;
  top: 0;
  width: 80px;
  padding-top: 24px;
  padding-left: 44px;
  background: #fff;
}

.back_btn_inner img {
  cursor: pointer;
}

.cross_icon {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.cross_icon:after {
  content: '';
  position: absolute;
  width: 16px;
  height: 2px;
  background: #000000;
  transform: rotate(45deg);
  left: 0;
  top: 8px;
}

.cross_icon:before {
  content: '';
  position: absolute;
  width: 16px;
  height: 2px;
  background: #000000;
  transform: rotate(-45deg);
  left: 0;
  top: 8px;
}

.form-switch .form-check-input,
.form-switch {
  cursor: pointer;
}

.customize_pop_li {
  cursor: default !important;
}

.cm_grab {
  cursor: -webkit-grab;
  cursor: grab;
}

.slelect_zindex__menu-portal {
  z-index: 999 !important;
}

.se-navigation.sun-editor-common {
  display: none !important;
}


.cm_permis {
  border: 1px solid #BDBDBD;
  border-radius: 15px;
  margin-top: 25px;
}

.cm_permis > h6 {
  font-weight: 400;
  text-align: center;
  padding: 10px;
  border-radius: 14px 14px 0 0;
  color: #fff;
  margin: 0;
}

.cm_permis.cm_can > h6 {
  background: #4BAE4F;
}

.cm_permis.cm_not_can > h6 {
  background: #E92A1C;
}


.cm_permis ul li {
  font-size: 14px;
  padding: 10px;
  border-top: 1px solid #DEDEDE;
  border-bottom: 1px solid #DEDEDE;
  display: flex;
  justify-content: space-between;
  padding-left: 25px;
  position: relative;
}

.cm_permis ul li:before {
  content: '';
  position: absolute;
  left: 9px;
  top: 18px;
  width: 4px;
  height: 4px;
  background: #000;
  border-radius: 100%;
}

.cm_permis ul li:last-child {
  border-bottom: 0;
}

.cm_permis ul li:last-child:before {
  display: none !important;
}