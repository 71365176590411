.invoice_main_discount_tax {
  padding: 0px;
}

.dashboard_heading {
  margin-bottom: 10px;
  /* font-size: 1em; */
}

.discount_item,
.tax_item {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.discount_item input,
.tax_item input {
  flex: 1;
}

.discount_item img,
.tax_item img {
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  /* background-color: #0056b3; */
}

.error {
  color: red;
  font-size: 11px;
  line-height: 15px;
}
.error_para {
  height: 15px;
}
.dropdown-container {
  position: relative;
  display: inline-block;
}

.resources_dropdown .dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  margin-left: -30px;
  margin-top: 10px;
}

.arrow-icon {
  color: #aaa6a6;
  right: 7px;
}

.resources_dropdown .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #ddd;
  padding: 8px;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.arrow-icon {
  color: #aaa6a6;
  right: 7px;
}

.resources_dropdown .dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  border: 1px solid #ddd;
  padding: 8px;
  background-color: white;
  min-width: 160px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content {
  white-space: normal;
  word-wrap: break-word;
}

.billing_container {
  display: flex;
  justify-content: space-between;
}
.billing_container1 {
  align-items: center;
  border: 1px solid #0000001a;
  padding: 34px;
  border-radius: 8px;
}
.billing_container1 .billing_column p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.invoice_main_list {
  margin-bottom: 20px;
}

.billing_sections {
  display: flex;
  justify-content: space-between;
}

.billing_details {
  margin-top: 10px;
}
.billing_details p {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

.billing_section h3 {
  margin-bottom: 10px;
}

.invoice_main_list_1 .modal_main_ul_1 {
  justify-content: space-between;
  width: 100%;
}

.billing_column h3 {
  font-weight: 600;
  margin-bottom: 15px;
}

.invoice_main_list_1 {
  margin-top: 23px;
}

.billing_column1 {
  text-align: end;
}

.preview_edit {
  display: flex !important;
  gap: 10px !important;
  justify-content: space-between !important;
  /* align-items: center; */
  margin: 16px 0px !important;
  gap: 10px !important;
}

.invoice_main_lower_left_main:focus {
  border-color: rgb(188, 186, 186); /* Change border color when focused */
  box-shadow: none; /* Remove default focus shadow */
}

.no-data-message {
  text-align: center;
  padding: 20px;
  color: #000000; /* You can adjust the color as needed */
  font-size: 16px;
  /* font-weight: bold; */
  margin-top: 13%;
}
.invoice_table {
  margin-top: 30px;
}
.invoice_table tr th,
.invoice_table tr td {
  padding-left: 30px;
  text-align: start;
  border: 1px solid #ececec;
}
.invoice_table tr td .modal_main_li {
  margin-bottom: 0;
}
.invoice_table tr td .modal_main_li input {
  border: 0;
  padding: 0;
  background: transparent;
}
.invoice_table tr td .modal_main_li .error_para {
  margin-bottom: 0;
}
.description_input {
  max-width: 200px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.handleAddItem {
  display: inline-flex;
  margin: 15px 15px 15px 30px;
  cursor: pointer;
}
.invoice_main_lower {
  /* margin-top: 45px; */
}
.invoice_total_main {
  border-bottom: 1px solid #e3e3e3;
  padding: 18px 29px 18px 64px;
}
.invoice_total_main_sub_total{
  padding-right: 50px;
}
.invoice_total_main .dashboard_heading {
  font-size: 14px;
  line-height: 18px;
  color: #1e1e1e;
  margin-bottom: 0;
}
.invoice_total_main input {
  border: 0;
  outline: 0;
  font-size: 14px;
  color: #0e1e2e;
  font-weight: 700;
  text-align: end;
}
.invoice_total_dropdown3,
.invoice_total_dropdown2,
.invoice_total_dropdown1 {
  margin-bottom: 0;
}
.invoice_total_dropdown1 ._editable_ input {
  padding: 0;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
}
.invoice_total_dropdown2 ._editable_ {
  justify-content: end;
}
.invoice_total_dropdown2 ._editable_ input {
  padding: 8px 22px;
  border: 1px solid #0e1e2e1a;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px 0 0 8px;
  max-width: 100px;
}
.invoice_total_dropdown3 ._dropdown_ {
  min-height: auto;
  width: 70px;
  border-radius: 0 8px 8px 0;
}
.invoice_total_dropdown3 img {
  top: 14px;
}
.invoice_total_dropdown3 .options {
  top: 40px;
  border: 1px solid #e3e3e3;
  padding: 10px;
}
.invoice_total_dropdown3 .option {
  margin-top: 0px !important;
}
.invoice_total_dropdown3 ._dropdown_ input {
  padding: 8px 12px;
  text-align: start;
  font-weight: 400;
}
.invoice_total_dropdown3 ._editable_ {
  /* justify-content: end; */
}
.invoice_total_main_right2 input {
  padding: 0;
  color: #0e1e2e;
  font-weight: 400;
}
.invoice_total_main_right2 {
  margin-bottom: 0;
}
.invoice_total_main_right2 img {
  cursor: pointer;
}
.invoice_main_total {
  padding: 33px 50px 33px 64px;
  background-color: #f7faff;
  border-radius: 0 0 8px 8px;
  border-bottom: 0;
}
.invoice_main_total .dashboard_heading {
  font-size: 20px;
  line-height: 24px;
}
.invoice_main_total input {
  padding: 0;
  background-color: transparent;
  color: #0e1e2e;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}
.add_span {
  font-size: 14px;
  margin-left: 64px;
  margin-top: 5px;
  display: inline-flex;
}
.invoice_main_total .invoice_total_main_right {
  margin-bottom: 0;
}
.invoice_total_main .invoice_total_main_right input {
  padding: 0;
  background-color: transparent;
}
.invoice_total_main .invoice_total_main_right {
  margin-bottom: 0;
}


.offline_payment-setup {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #673ab7;
  background-color: #f7f7f7;
  padding: 10px 20px;
  border: 1px dashed #673ab7;
  border-radius: 4px;
}

.offline_payment-setup strong {
  font-weight: 600;
}

.offline_container {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.offline_record {
  font-size: 22px;
  margin-bottom: 30px;
}

.offline_form {
  display: flex;
  flex-direction: column;
}

.offline_form-row {
  display: flex;
  justify-content: space-between;
}

.offline_form-group {
  width: 49%;
  margin-bottom: 20px;
}

.offline_form-group:last-child {
  margin-right: 0;
}

.offline_label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.offline_input {
  width: 100%;
}
.react-datepicker-wrapper {
  width: 100%;
}
.offline_input1,
.offline_input1 input {
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 5px 10px;
}
.full-width{
  width: 100%;
}

.offline_input:focus {
  border-color: #007bff;
  outline: none;
}
.full-width-textarea{
  width: 100%;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  padding: 5px 10px;
}

.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.full-width {
  flex: 100%;
}

.offline_payment-setup {
  margin-top: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 15px;
}
.offline_payment-setup div p{
  margin-bottom: 4px;
  text-align: start;
}

.contract_main_button {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}


#invoice-preview body{
    font-size: 14px;
}

#invoice-preview .dashboard_heading {
  font-size: 20px;
}


